import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {DATEFORMAT, MESSAGES} from '../shared/constants/';
import {IMyDpOptions, IMyDate} from 'mydatepicker';
import {NotificationService} from '../shared/service/notification.service';
import {DataService} from '../shared/service/data.service';
import {ErrorService} from '../shared/service/error-service';
import {ConstantService} from '../shared/service/constant-service';
import {Facility} from '../shared/model/facility';
import {ExportFormat, ReportService} from '../shared/service/report/report.service';
import {ReportTypeService} from '../shared/api/report.type.service';
import {Metric} from '../shared/model/report.type';
import {TimeShift, GetDisplayStringForTimeShift} from '../shared/model/time.shift';
import {FacilityService,HealthSystemService} from '../shared/api';
import {ReportDataService} from '../shared/api/report.data.service';
import {Policy, PolicyQuestionAnswer} from '../shared/model/policy';
import { PolicyService } from '../shared/api/policy.service';
import { PolicyTypeService } from '../shared/api/policy.type.service';
import {FullReport} from '../shared/model/unit.report';
import {ReportContext} from '../shared/service/report/report.context';
import {
    Cell, ComprehensiveType, ReportType, reportTypes, setSingleMetricsHeader, ReportView, Table, TwentyOneDayChallengeType,
    ViewTab,
} from '../shared/constants/reports';
import {FacilitySelectorComponent} from '../facility-selector/facility-selector.component';
import AbstractSkeletonLoader from '../shared/animations/AbstractSkeletonLoader';
import { PopupMenuService } from '../shared/components/popup-menu/popup-menu.service';
import {IMyDateModel,IMyMarkedDates} from 'mydatepicker';
import { REPORTS_PAGE_COMPONENT as UI_CONSTANTS, CREATE_AND_EDIT_POLICY_MODAL_DIALOG_COMPONENT } from '../shared/constants/uiConstants';
import { trigger, style, animate, transition } from '@angular/animations';
import { ReportDetailsService, ReportDetails } from "../shared/api/report.details.service";
import {UserService} from "../shared/api";

import { SimpleModalService } from 'ngx-simple-modal';
import { DisplayExportAnalyticDataModalDialogMode, ExportAnalyticDataComponent } from "./export-analytic-data/export-analytic-data.component";
import {MODAL_DIALOG_FADE_DURATION_MS} from '../shared/constants/animationConstants';
import { Question } from '../shared/model/question';
import { QuestionAnswer } from '../shared/model/question-answer';
import { PolicyType } from "../shared/model/policy-type";
import { HealthSystem } from '../shared/model/health-system';
import { TokenService } from '../shared/service/token.service';
import * as $ from 'jquery';

interface TimeShiftDisplay {
    label: string;
    timeShift: TimeShift;
}

interface PolicyResponse {
    question: string;
    answer: string[];
}

interface ReportDetailsLocal {
    policyType: number;
    timeShiftAvailable: number;
    availableDates: Array<ReportDateLocal>;
    
  }
  interface ReportDateLocal{
    day:number;
    month:number;
    year:number;

  }

@Component({
    templateUrl: 'dashboard.component.html',
    styleUrls: ['dashboard.component.scss'],
    animations: [
        trigger('opacityFade', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.3s', style({ opacity: 1 })),
            ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('0.3s', style({ opacity: 0 })),
        ])])],
})
export class DashboardComponent extends AbstractSkeletonLoader implements OnInit {
    public fromDate: IMyDateModel;
    public toDate: IMyDateModel;
    public resetDate: IMyDateModel;

    public selectedReportType: ReportType;
    public REPORT_TYPES: ReportType[] = [];
    public SELECTED_REPORT_TYPES: ReportType[] = [];
    public facility: Facility;
    public facilities: Facility[] = [];

    public fromDatePickerOptions: IMyDpOptions;
    public toDatePickerOptions: IMyDpOptions;
    private answersMap: Map<number, PolicyQuestionAnswer> = new Map<number, PolicyQuestionAnswer>();

    public formats = {
        excel: ExportFormat.EXCEL,
        ppt: ExportFormat.PPT,
        pdf: ExportFormat.PDF
    };

    public showReportTypeDropdown: boolean = false;
    public showViewDropdown: boolean = false;
    public showShiftDropdown: boolean = false;
    public showMetricDropdown: boolean = false;
    public shiftFilterReportDetails: ReportDetails;
    public selectedTimeShift: TimeShift;
    public timeShifts: TimeShift[] = [TimeShift.BOTH, TimeShift.DAY, TimeShift.NIGHT];
    public UI = UI_CONSTANTS;
    public tooltipmessage:string;    

    // TimeShiftDisplay is used for UI only, to give a friendly label instead of an upper case constant
    public timeShiftDisplays: TimeShiftDisplay[] = [
        {
            label: GetDisplayStringForTimeShift(TimeShift.SELECT),
            timeShift: TimeShift.SELECT,
        },
        {
            label: GetDisplayStringForTimeShift(TimeShift.BOTH),
            timeShift: TimeShift.BOTH,
        },
        {
            label: GetDisplayStringForTimeShift(TimeShift.DAY),
            timeShift: TimeShift.DAY,
        },
        {
            label: GetDisplayStringForTimeShift(TimeShift.NIGHT),
            timeShift: TimeShift.NIGHT,
        },
    ];
    public selectedTimeShiftDisplay: TimeShiftDisplay = this.timeShiftDisplays[0];

    public fullReport?: FullReport;
    public fullReportDay?: FullReport;
    public fullReportNight?: FullReport;
    public fullReportBoth?: FullReport;
    private isReportLoading: boolean = false;
    private isReportDataErrored: boolean = false;
    public policy?: Policy;
    private isPolicyLoading: boolean = false;
    private isPolicyErrored: boolean = false;
    public table?: Table;
    public selectedReportView?: ReportView;
    public metric?: Metric;
    private _policies: Policy[] = [];
    public  policies: Policy[] = [];
    public selectedPolicy:Policy;
    public isFaclityChanged: boolean = false;
    //policyTypes: import("../shared/model/policy-type").PolicyType[];
    public policyTypes: PolicyType[]=[];
    public selectForHealthSystem: boolean = false;
    public selectedHealthSystem: string = undefined;    
    public healthSystem:HealthSystem[] = [];
    public healthSystemValues=[];

    

    @ViewChild('exportButton', {read: ViewContainerRef}) exportButtonViewContainerRef: ViewContainerRef;
    
    constructor(
        private notificationService: NotificationService,
        private dataService: DataService,
        private errorService: ErrorService,
        private constantService: ConstantService,
        private reportService: ReportService,
        private reportTypeService: ReportTypeService,
        private facilityService: FacilityService,
        private reportDataService: ReportDataService,
        private popupMenuService: PopupMenuService,
        private reportDetailsService: ReportDetailsService,
        private userService: UserService,
        private simpleModalService: SimpleModalService,
        private policyService: PolicyService,
        private policyTypeService: PolicyTypeService,
        private healthSystemService: HealthSystemService,
        private tokenService: TokenService,
    ) {
        super();
    }

    public ngOnInit() {
        this.loadPolicyTypes();
        this.loadPolicies();
        this.loadHealthSystem();
        this.loadFacility();
        this.selectedTimeShift = TimeShift.BOTH;
        this.REPORT_TYPES = reportTypes();
        this.selectedReportType = this.REPORT_TYPES[0];
        if (this.selectedReportType.views) {
            this.selectedReportView = this.selectedReportType.views[0];
            this.selectedReportType.selectView(this.selectedReportView);
            if (this.selectedReportView.tabs) {
                this.selectedReportView.selectTab(this.selectedReportView.tabs[0]);
            }
        }

        this.fromDatePickerOptions = {
            dateFormat: DATEFORMAT.mmmdd_yyyy,
            firstDayOfWeek: 'su',
            
       
        };

        this.toDatePickerOptions = {
            dateFormat: DATEFORMAT.mmmdd_yyyy,
            firstDayOfWeek: 'su', 
            
        };
        //if(this.isUserAdminForSelectedFacility)
            this.onSelectedFacilityChanged(this.facility);

    }

    loadPolicyTypes() {
        this.policyTypeService.getAllEntities().subscribe(
            policyTypes => {
                this.policyTypes = policyTypes;
                console.log(this.policyTypes);
            },
            err => {
                this.errorService.handleErrorStatusCode(err);
            }
        );
    }

    public isFacilityValidForReport(facility: Facility): boolean {
        if (facility && facility.id != FacilitySelectorComponent.ALL_FACILITIES_ID) {
            return true;
        } else {
            return false;
        }
    }
    public get isUserAdminForSelectedFacility():boolean{

        if(this.facility && this.facility.id != FacilitySelectorComponent.ALL_FACILITIES_ID && !this.userService.isUserAdminAtFacilityToEditAudit(this.facility.id)){
           return false;
        }
        return true;
    }
    public get disableActionBar():boolean{

        return !this.isUserAdminForSelectedFacility;
    }

    // Called from facility-selector component
    public onSelectedFacilityChanged(newFacility: Facility) {
        this.isFaclityChanged = true;
        this.policies=this.policiesForFacilityId(newFacility.id);      
        this.fromDatePickerOptions = {
            dateFormat: DATEFORMAT.mmmdd_yyyy,
            firstDayOfWeek: 'su',
            markDates: [{dates: [], color: 'Orange'}],
            disableDateRanges: [
                {
                    begin: {year: 2000, month: 1, day: 1}, 
                    end: {year: 2030, month: 12, day: 31}
                }],
            minYear: 2000,
            maxYear: 2030,
            enableDays: [],
        };

        this.toDatePickerOptions = {
            dateFormat: DATEFORMAT.mmmdd_yyyy,
            firstDayOfWeek: 'su', 
            markDates:[{dates: [], color: 'Pink'}],
            disableDateRanges: [
                {
                    begin: {year: 2000, month: 1, day: 1}, 
                    end: {year: 2030, month: 12, day: 31}
                }],
            minYear: 2000,
            maxYear: 2030,
            enableDays: [],
        };
        if(newFacility && newFacility.id != FacilitySelectorComponent.ALL_FACILITIES_ID && !this.userService.isUserAdminAtFacilityToEditAudit(newFacility.id)){
            
            this.notificationService.warn('','You are not the Admin for selected facility. Please select any other facility.');
        }
        this.facility = newFacility;
        this.fromDate =  null;
        this.toDate =  null;
        this.selectedTimeShiftDisplay= this.timeShiftDisplays[0];
        this.selectedTimeShift = undefined ;
       this.clearAllReportData();

        //if(newFacility!=null)
        //    this.markDates(this.policy.id)
        
        if (this.isFacilityValidForReport(this.facility)) {
           this.loadActivePolicy();
        }
        
        
        //this.onFilterChange();
        //this.clearAllReportData();
    }
    public isAdminForSelectedFaclility(facilityId:number):boolean{
        return this.userService.isUserAdminAtFacilityToEditFaclility(facilityId);
    }
    public markDates(policyId:number){
        if(policyId === 0) 
            return;

        this.timeShiftDisplays = [
            {
                label: GetDisplayStringForTimeShift(TimeShift.SELECT),
                timeShift: TimeShift.SELECT,
            },
            {
                label: GetDisplayStringForTimeShift(TimeShift.BOTH),
                timeShift: TimeShift.BOTH,
            },
            {
                label: GetDisplayStringForTimeShift(TimeShift.DAY),
                timeShift: TimeShift.DAY,
            },
            {
                label: GetDisplayStringForTimeShift(TimeShift.NIGHT),
                timeShift: TimeShift.NIGHT,
            },
        ];

        if(this.fromDate !== null && this.toDate !== null)
        setSingleMetricsHeader(this.fromDate.toString() + " to " + this.toDate.toString());

        //this.REPORT_TYPES = reportTypes();    
        let policy:Policy =this._policies.find(_=>_.id == policyId); 
        let isHealthSystemPolicy:boolean = false;  
        
       // if(policy.healthSystemId != undefined)
       //  isHealthSystemPolicy = true;
        
        //console.log("Policy:"+policy.healthSystemId + ", HealthSystem:"+isHealthSystemPolicy);
        //return;         
        this.reportDetailsService.getReportDetails(policyId,this.selectForHealthSystem).subscribe(          
                reportData => {

                                this.shiftFilterReportDetails = reportData;
                              
                                //console.log(JSON.stringify(reportData.availableDates));
                                if(reportData.policyType == 2)
                                {
                                this.selectReportType(this.REPORT_TYPES[0]);
                                this.selectReportView(this.REPORT_TYPES[0].views[0]);                    
                                }else{
                                    this.selectReportType(this.REPORT_TYPES[1]);
                                    this.selectReportView(this.REPORT_TYPES[1].views[0]);
                                }
                                
                                let availableDates: Array<IMyDate> = [];
                                reportData.availableDates.forEach(element => {
                                    availableDates.push({year: element.year, month: element.month, day: element.day});
                                    
                                });

                        
                                this.fromDatePickerOptions = {
                                    dateFormat: DATEFORMAT.mmmdd_yyyy,
                                    firstDayOfWeek: 'su',
                                    markDates: [{dates: availableDates, color: 'Orange'}],
                                    disableDateRanges: [
                                        {
                                            begin: {year: 2000, month: 1, day: 1}, 
                                            end: {year: 2030, month: 12, day: 31}
                                        }],
                                    minYear: 2000,
                                    maxYear: 2030,
                                    enableDays: availableDates,
                                };

                                this.toDatePickerOptions = {
                                    dateFormat: DATEFORMAT.mmmdd_yyyy,
                                    firstDayOfWeek: 'su', 
                                    markDates:[{dates: availableDates, color: 'Pink'}],
                                    disableDateRanges: [
                                        {
                                            begin: {year: 2000, month: 1, day: 1}, 
                                            end: {year: 2030, month: 12, day: 31}
                                        }],
                                    minYear: 2000,
                                    maxYear: 2030,
                                    enableDays: availableDates,
                                };
               
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
    }

    private displayExportAnalyticData(): void {
        this.simpleModalService.addModal(
            ExportAnalyticDataComponent,
            {mode:DisplayExportAnalyticDataModalDialogMode.Create},
            {
                animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
            }
        );        
      }

    public onExportClicked() {
        // To add menu options like Excel and PDF in the future just add to the menuOptions string array and respond to the corresponding indexSelected.
        const menuOptions = ['To PowerPoint', 'Data To Excel'];
        let pptPolicy: Policy;       
        this.popupMenuService.openMenu(this.exportButtonViewContainerRef, menuOptions, (indexSelected) => {
            switch (indexSelected) {
                case 0:
                  //  this.exportAll(this.formats.ppt);
                   // this.exportCurrent(this.formats.ppt);
                   if (this.selectedPolicy && this.selectedPolicy.answers) {
                    pptPolicy = this.policies.filter(x=> x.id == this.selectedPolicy.id)[0];
                    for (let answer of this.selectedPolicy.answers) {
                      this.answersMap.set(answer.questionAnswerId, answer);
                    }
                  }

                  const questionResponses : Array<PolicyResponse> = [];
                  const compliance = {
                    question: "Compliance reporting settings",
                    answer: ["Minimum compliance rate % that should display on reports as yellow: " + this.selectedPolicy.settings.minYellowRate.toString(), 
                    "Minimum compliance rate % that should display on reports as green: " + this.selectedPolicy.settings.minGreenRate,
                    CREATE_AND_EDIT_POLICY_MODAL_DIALOG_COMPONENT.COMPLIANCE_RATES.FOOTER]
                };
                  questionResponses.push(compliance);

                  if (this.policyTypes) {
                    for (let question of this.policyTypes[this._policyTypeId - 1].questions) {
                        if(question.answers !== null){
                            const ansArray = [];
                        for (let questionAnswer of question.answers) {
                            const answer = this.answersMap.get(questionAnswer.id);
                            if (answer) {
                                var ansText = question.answers.filter(x=>x.id == answer.questionAnswerId)[0].label;
                                ansArray.push(ansText);
                            }
                        }
                        if(ansArray.length > 0){
                            const response: PolicyResponse = {
                                question: question.title,
                                answer: ansArray
                            };
                            questionResponses.push(response);
                        }

                    }
                    }

                    var count = 1;
                    if(pptPolicy.customQuestions != null && pptPolicy.customQuestions.length> 0)
                    pptPolicy.customQuestions.forEach(element => {
                            var askRate = element.forEveryPatientOrCatheter==0? ' Once per Patient':' Once per Line';
                            const customQues: PolicyResponse = {
                                question: "Custom Question "+ count,
                                answer: [element.questionText, "How often should this question be asked?" + askRate]
                            };
                            questionResponses.push(customQues);
                            count++;
                        });
                }

                   this.exportAllShifts(this.formats.ppt, questionResponses);
                   this.reportDataService.insertReportDownloadLog(pptPolicy.id, 'PPT').subscribe();
                break;
                case 1:
                    this.exportData();
                    let policyId = this.policy.id;
                    this.reportDataService.insertReportDownloadLog(policyId, 'Excel').subscribe();
                break;
                default:
                    console.assert(false, 'DashboardComponent#onExportClicked - Invalid index selected');
                break;
            }
        });
    }

    public exportData() {
        //this.showSkeletonAfterDelay(0);
        //$("#loading").show();
        this.reportService.exportRawReport(
            this.policy.id,
            this.selectedTimeShift,
            this.convertDate(this.fromDate),
            this.convertDate(this.toDate),
            this.selectedReportType,
            this.formats.excel,
            this.selectForHealthSystem,
            this.selectedHealthSystem
        );
    }
    public exportAnalyticData() {
        
       // this.reportDataService.reportAnalyticData().subscribe(analyticData=>{ 
       //     console.log("Length:"+analyticData.length);
       //     console.log("Data:"+JSON.stringify(analyticData));
            
      //  })
        this.reportService.exportAnalytic();
     
    }
    public isSuperAdmin():boolean{

       // console.log("UserRole:"+JSON.stringify(this.userService.getPrimaryRoleForCurrentUser()));

        if(this.userService.getPrimaryRoleForCurrentUser() == "ROLE_SUPER_ADMIN")
            return true;
        return false;
    }

    private get currentReportContext(): ReportContext {
        return new ReportContext(
            this.selectedReportType,
            this.policy,
            this.facility,
            this.table,
            this.fullReport,
            this.convertDate(this.fromDate),
            this.convertDate(this.toDate),
            this.selectedTimeShift,
            this.selectedReportView,
            this.metric
        );
    }
    private get currentReportContextDay(): ReportContext {
        return new ReportContext(
            this.selectedReportType,
            this.policy,
            this.facility,
            this.table,
            this.fullReportDay,
            this.convertDate(this.fromDate),
            this.convertDate(this.toDate),
            this.timeShifts[1],
            this.selectedReportView,
            this.metric
        );
    }
    private get currentReportContextNight(): ReportContext {
        return new ReportContext(
            this.selectedReportType,
            this.policy,
            this.facility,
            this.table,
            this.fullReportNight,
            this.convertDate(this.fromDate),
            this.convertDate(this.toDate),
            this.timeShifts[2],
            this.selectedReportView,
            this.metric
        );
    }
    private get currentReportContextBoth(): ReportContext {
        return new ReportContext(
            this.selectedReportType,
            this.policy,
            this.facility,
            this.table,
            this.fullReportBoth,
            this.convertDate(this.fromDate),
            this.convertDate(this.toDate),
            this.timeShifts[0],
            this.selectedReportView,
            this.metric
        );
    }

    //not being used
    public exportCurrent(format: ExportFormat = ExportFormat.PPT) {
        this.reportService.exportReport(this.currentReportContext, format);
    }

    //not being used
    public exportAll(format: ExportFormat = ExportFormat.PPT,questionResponse: Array<PolicyResponse>) {
        this.reportService.exportAllReports(this.currentReportContext, format, questionResponse, this.selectedPolicy);
    }

    //exports report - current usage
    public exportAllShifts(format: ExportFormat = ExportFormat.PPT, questionResponse: Array<PolicyResponse>) {
        //console.log("this.timeShiftDisplays:"+this.timeShiftDisplays.length);return;
        //console.log("this.currentReportContextBoth:"+JSON.stringify(this.currentReportContextBoth));
        //console.log("this.currentReportContext:"+JSON.stringify(this.currentReportContext));
       // this.reportService.exportAllReports(this.currentReportContext, format, questionResponse, this.selectedPolicy);

        if(this.timeShiftDisplays.length>2){ 

            if(this.selectForHealthSystem){
                
                this.reportService.exportAllShiftReports(this.currentReportContextDay,this.currentReportContextNight,this.currentReportContextBoth, format, questionResponse, this.selectedPolicy, this.selectedHealthSystem);

            }else{

                this.reportService.exportAllShiftReports(this.currentReportContextDay,this.currentReportContextNight,this.currentReportContextBoth, format, questionResponse, this.selectedPolicy);
            }
        }   
        else{
            if(this.selectForHealthSystem){
                console.log("HS:"+this.selectedHealthSystem);
                    
                this.reportService.exportAllReports(this.currentReportContext, format, questionResponse, this.selectedPolicy,this.selectedHealthSystem);

            }else{

                this.reportService.exportAllReports(this.currentReportContext, format, questionResponse, this.selectedPolicy);
            }
            
        }
    }

    public onChangeOfFromDate() {
        if (this.fromDate) {
            this.toDatePickerOptions = {
                dateFormat: DATEFORMAT.mmmdd_yyyy,
                editableDateField: false,
                disableUntil: this.fromDate.date,
                //disableUntil:{year: this.fromDate.date.year, month: this.fromDate.date.month, day: this.fromDate.date.day - 1},
                showInputField: false,
                showClearDateBtn: false,
            };

            if (this.toDate) {
                // Clamp the toDate so that it is always the same or after fromDate
                if (this.toDate.jsdate < this.fromDate.jsdate) {
                    this.toDate = this.fromDate;
                }
            }

        }
        this.onChangeToDate();
    }
    public createDateObject(myDate:IMyDate):Date{
        let dateString:string= myDate.year+'/'+myDate.month+'/'+myDate.day;
        let newDate = new Date(dateString);
        //console.log(dateString);
        return newDate;
    }
     public onChangeToDate(){
        
        if(this.toDate == null || this.toDate == undefined)
        return;
        if(this.fromDate == null || this.fromDate == undefined)
        return;
        let repType = 0;
        let shift:string[]=[];
        this.shiftFilterReportDetails.availableDates.forEach((element) =>{
            let repDate:IMyDate;
            repDate = {year: element.year, month: element.month, day: element.day}

            if(this.createDateObject(this.fromDate.date) <= this.createDateObject(repDate) && this.createDateObject(this.toDate.date) >= this.createDateObject(repDate)){
                //console.log("asdsad");
                shift.push(element.shift);
            }else{
                //console.log("ERROR ERROR");
                //console.log("From Date:"+JSON.stringify(this.createDateObject(this.fromDate.date)));
                //console.log("To Date:"+JSON.stringify(this.createDateObject(this.toDate.date)));
                //console.log("Report Date:"+JSON.stringify(this.createDateObject(repDate)));
               // shift.push(element.shift);
            }
            
        })

        this.timeShiftDisplays = [
            {
                label: GetDisplayStringForTimeShift(TimeShift.SELECT),
                timeShift: TimeShift.SELECT,
            },
            {
                label: GetDisplayStringForTimeShift(TimeShift.BOTH),
                timeShift: TimeShift.BOTH,
            },
            {
                label: GetDisplayStringForTimeShift(TimeShift.DAY),
                timeShift: TimeShift.DAY,
            },
            {
                label: GetDisplayStringForTimeShift(TimeShift.NIGHT),
                timeShift: TimeShift.NIGHT,
            },
        ];
        
        if(shift.includes("DAY")  && !shift.includes("NIGHT")){
            this.timeShiftDisplays.splice(3,1);
            this.timeShiftDisplays.splice(1,1);
        }else if(!shift.includes("DAY")  && shift.includes("NIGHT")){
            this.timeShiftDisplays.splice(2,1);
            this.timeShiftDisplays.splice(1,1);
        }

        this.selectedTimeShiftDisplay= this.timeShiftDisplays[0];
        this.selectedTimeShift = undefined ;
        
    } 

    public get areSelectedReportOptionsValid(): boolean {
        
        if (this.facility != null
            && this.isUserAdminForSelectedFacility
            && this.facility.id !== FacilitySelectorComponent.ALL_FACILITIES_ID
            && this.fromDate != null
            && this.toDate != null
            && this.selectedTimeShift != null
        ) {
            return true;
        } else {
            return false;
        }
    }

    public get canLoadReport(): boolean {
        return this.selectedPolicy != null && this.areSelectedReportOptionsValid;
    }

    public onFilterChange() {
        
        this.clearAllReportData();
        if (this.canLoadReport) {
            this.loadReportData();    
        }
    }

    public clearAllReportData() {
        this.table = undefined;
        this.fullReport = undefined;
    }

    public getStyleClassForCell(cell: Cell) {
        if (cell.header) {
            return {'transparent-cell': true};
        } 
        //INC0421825
        else if (cell.value == 'N/A') {
            return {'gray-cell': true};
        } else if (cell.value == '') {
            return {'empty-cell': true};
        } else if (cell.metaValue >= this.policy.settings.minGreenRate) {
            return {'green-cell': true};
        } else if (cell.metaValue >= this.policy.settings.minYellowRate) {
            return {'yellow-cell': true};
        } else {
            return {'red-cell': true};
        }
    }

    public get shouldShowTable(): boolean {
        return false == this.shouldShowEmptyTableIndicator && this.shouldShowContent && this.arePolicyAndReportLoaded && (this.table != null);
    }

    public get shouldShowActionBar(): boolean {
        return this.table != null || (this.selectedReportType != null && this.selectedReportType.metrics != null);
    }

    public get shouldShowEmptyTableIndicator() {
        if (this.isReportLoading) {
            return false;
        }
        if (false == this.policyAndReportTypeMatch()) {
            return true;
        }
        if (this.areSelectedReportOptionsValid) {
            if (this.isReportDataErrored) {
                return true;
            }
            return this.arePolicyAndReportLoaded && this.table == null;
        } else {
            if (this.isPolicyLoading && this.areSelectedReportOptionsValid) {
                return false;
            } else {
                return true;
            }
        }
    }

    private policyAndReportTypeMatch(): boolean {
        const twentyOneDayReportTypeId: number = 1;
        const comprehensiveReportTypeId: number = 2;
        if (undefined == this.policy) {
            return false;
        }
        if (this.selectedReportType.title == TwentyOneDayChallengeType.TITLE
            && this.policy.policyTypeId != twentyOneDayReportTypeId
        ) {
            return false;
        }
        if (this.selectedReportType.title == ComprehensiveType.TITLE
            && this.policy.policyTypeId != comprehensiveReportTypeId
        ) {
            return false;
        }
        return true;
    }

    public get emptyTableHint() {
        if (this.isPolicyErrored) { 
            return UI_CONSTANTS.HINTS.NO_POLICY;
        }

        if (this.isReportDataErrored) {
            return UI_CONSTANTS.HINTS.NO_DATA;
        }
        if(this.disableActionBar){

            return UI_CONSTANTS.HINTS.SELECT_OTHER_FACILITY;
        }

        if (this.areSelectedReportOptionsValid) {
            return UI_CONSTANTS.HINTS.NO_DATA;
        } else {
            if (this.isFacilityValidForReport(this.facility)) {
                return UI_CONSTANTS.HINTS.SELECT_OPTIONS;
            } else {
                return UI_CONSTANTS.HINTS.SELECT_FACILITY;
            }
        }
    }

    public doesSelectedReportViewHaveTabs(): boolean {
        return this.selectedReportView != null && this.selectedReportView.tabs != null && this.selectedReportView.tabs.length > 0;
    }
    public reportTypeChange(newReportType: ReportType) {
        if(!this.isFaclityChanged)
            this.selectedPolicy = null;
        this.isFaclityChanged = false;
        this.selectReportType(newReportType);
    }

    public selectReportType(newReportType: ReportType) {
        if(this.facility != null)
        this.policies = this.policiesForFacilityId(this.facility.id);
       // this.selectedPolicy = null;
       
        this.selectedReportView = undefined;
        this.metric = undefined;
        this.selectedReportType = newReportType;
        console.log(newReportType);
        if (this.selectedReportType.metrics) {
            this.metric = this.selectedReportType.metrics[0];
        }
        if (this.selectedReportType.views) {
            this.selectedReportView = this.selectedReportType.views[0];
            this.selectedReportType.selectView(this.selectedReportView);
        }

        this.showReportTypeDropdown = false;
        this._prepareTableData();
        this.clearAllReportData();
    }

    public selectReportView(view: ReportView) {
        this.selectedReportView = view;
        this.selectedReportType.selectView(this.selectedReportView);
        this.showViewDropdown = false;
        this._prepareTableData();
    }

    public selectTimeShiftDisplay(timeShiftDisplay: TimeShiftDisplay) {  
        if(this.selectedTimeShiftDisplay.label == 'Select'){
            //this.clearAllReportData;
            return;
        }
        this.selectedTimeShiftDisplay = timeShiftDisplay;
        if (this.selectedTimeShift !== timeShiftDisplay.timeShift || this.selectedTimeShift == undefined) {
            this.selectedTimeShift = timeShiftDisplay.timeShift;    
            this.onFilterChange();    
        }
    }

    public selectMetric(metric: Metric) {
        this.metric = metric;
        this._prepareTableData();
    }

    private loadFacility(){

        
       /* this.facilityService.getAllActive().then(records => {
            this.facilities = records;           
            //console.log(JSON.stringify(this.facilities));
    
        });*/

        this.facilityService.getAll().subscribe(_ => {
            const active = _.filter(_ => _.active);
            this.facilities =active;
        });



    }

    private loadActivePolicy() {
        if (this.areSelectedReportOptionsValid) {
            this.showSkeletonAfterDelay(0);
        }

        this.policy = null;
        this.isPolicyErrored = false;
        this.isPolicyLoading = true;

        this.facilityService.activePolicy(this.facility.id)
            .subscribe(policy => {
                this.policy = policy;
                //this.selectedPolicy = policy;//this.policies.filter(p=>p.id == policy.id)[0];
                
                //INC0333618 - Not selecting the active policy in automatically
                var tmpPolicy = policy;
                tmpPolicy.name = "Select";
                this.selectedPolicy = tmpPolicy;
                
                //this.policies = this.policies.filter(p=>p.id !== policy.id);
                console.log("Seleceted Policy:"+this.selectedPolicy.name);
                //this.checkHealtSystem()
               // this.clearAllReportData();
                //this.selectPolicyName(policy);
                this.markDates(this.policy.id); 
                this.isPolicyLoading = false;
                //this.selectedPolicy = policy;

                if (this.arePolicyAndReportLoaded) {
                    this.hideSkeletonAndShowContent();
                }
            }, () => {
                this.isPolicyLoading = false;
                this.isPolicyErrored = true;
                this.hideSkeletonAndShowContent();
            });
    }

    public isTabSelected(tab: ViewTab): boolean {
        return this.selectedReportView != null && this.selectedReportView.isTabSelected(tab);
    }

    public selectReportViewTab(tab: ViewTab) {
        this.selectedReportView.selectTab(tab);
        this._prepareTableData();
    }

    public get allReportViews(): ReportView[] {
        if (this.selectedReportType == null || this.selectedReportType.views == null) {
            return [];
        } else {
            return this.selectedReportType.views;
        }
    }

    // Used by the UI to enable the footer information
    public get isPolicyLoaded(): boolean {
        return this.selectedPolicy != null;
    }

    public get arePolicyAndReportLoaded(): boolean {
        return this.fullReport != null && this.selectedPolicy != null;
    }

    private loadReportData() {
        const fromDate = this.convertDate(this.fromDate);
        const toDate = this.convertDate(this.toDate);
        console.log("Getting Report Data...");
        $("#loading").show();
        this.showSkeletonAfterDelay(0);
        this.isReportLoading = true;
        this.isReportDataErrored = false;  
        
             
        console.assert(this.areSelectedReportOptionsValid, 'DashboardComponent#loadReportData - Trying to load report data for invalid options');
        this.reportDataService
            .reportData(this.selectedPolicy.id, this.selectedTimeShift, fromDate, toDate, this.selectForHealthSystem )
            .subscribe(data => {
                this.isReportLoading = false;
                this.fullReport = data;
                this._prepareTableData();
                if (this.arePolicyAndReportLoaded) {
                    this.hideSkeletonAndShowContent();
                    $("#loading").hide();
                    this.reportDataService.reportInitiateExcel(this.policy.id,
                        this.selectedTimeShift,
                        this.convertDate(this.fromDate),
                        this.convertDate(this.toDate),
                        this.selectedReportType.rawDataKey,
                        this.selectForHealthSystem).subscribe(data => {
                            //this.hideSkeletonAndShowContent();
                            //this.isReportLoading = false;
                            $("#loading").hide();
                           
                        }); 
                    
                }
            },
            () => {
                this.isReportLoading = false;
                this.isReportDataErrored = true;
                this.hideSkeletonAndShowContent();
                $("#loading").hide();
            });
            if(this.timeShiftDisplays.length>2){
             
                this.reportDataService
                .reportData(this.selectedPolicy.id, this.timeShifts[1], fromDate, toDate,this.selectForHealthSystem)
                .subscribe(data => {
                    
                    this.fullReportDay = data;  
                               
                    
                });
           
                this.reportDataService
                .reportData(this.selectedPolicy.id, this.timeShifts[2], fromDate, toDate,this.selectForHealthSystem)
                .subscribe(data => {
                    
                    this.fullReportNight = data;                
                    
                });
              
                this.reportDataService
                .reportData(this.selectedPolicy.id, this.timeShifts[0], fromDate, toDate,this.selectForHealthSystem)
                .subscribe(data => {
                    
                    this.fullReportBoth = data;                
                    
                });
        
            }
    }

    private _prepareTableData() {
        if (this.fullReport) {
            this.table = this.selectedReportType.prepare({data: this.fullReport, metric: this.metric, view: this.selectedReportView});
        }
    }

    private convertDate(date): Date | null {
        if (date) {
            const dateObj = new Date();
            dateObj.setFullYear(date.date.year, date.date.month - 1, date.date.day);
            return dateObj;
        }

        return null;
    }
    public showHeaderToolTip(toolTipMsg:string){

        this.tooltipmessage=toolTipMsg;
    }

    //Policy Services 

    private loadPolicies(): Promise<void> {
        return new Promise(resolve => {
            this.policyService.getAllEntities().subscribe(
            //this.policyService.getPolicyByIndex(this.policyIndex,facilId).subscribe(
                policies => {
                   /*  policies.forEach(data => {
                        this._policies.push(data);
                    }); */
                    this._policies=policies;
                    //this.setActivePolicy();
                    console.log("Policies are loaded");
                    resolve();
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
        });
    }
	
	public policiesForFacilityId(facilityId: number | undefined): Policy[] {
        
        if(facilityId != FacilitySelectorComponent.ALL_FACILITIES_ID && this._policies.filter(policy => policy.facilityId === facilityId).length === 0){
            this.selectedPolicy = null;
            //this.notificationService.error("", 'No acitve policy found for selection, Please select other facility');
        }
        if(this.selectForHealthSystem == true)
            return this._policies.filter(policy => policy.facilityId === facilityId && policy.policyTypeId === this._policyTypeId && policy.healthSystemId !=undefined );

        return this._policies.filter(policy => policy.facilityId === facilityId && policy.policyTypeId === this._policyTypeId );
    }
	public selectPolicyName(policy:Policy){

            this.selectedPolicy=policy;
            this.policy = policy;
            //console.log(JSON.stringify(policy));
            this.fromDate = null;
            this.toDate = null;
            this.selectedTimeShiftDisplay = null;        
            this.markDates(policy.id);
            //this.onFilterChange();
    }

    public get _policyTypeId():number {       
        if(this.selectedReportType.title == '21-Days Challenge')
            return 1;
        return 2;
    }
    public checkHealtSystem(){
          //this.onSelectedFacilityChanged(this.facility);
          
        
          //this.onFilterChange();
          this.clearAllReportData();
          
          this.selectForHealthSystem ? (this.selectForHealthSystem = false) : (this.selectForHealthSystem = true);
          let facility:Facility[];
          this.selectPolicyName(this.selectedPolicy);
          
          if(this.selectForHealthSystem == true){ 

            this.policies = this._policies.filter(policy => policy.facilityId === this.facility.id && policy.policyTypeId === this._policyTypeId && policy.healthSystemId !=undefined);
  
           //  let facility:Facility = this.facility.find(_=>_.id == this.activeFacilityId);
  
             this.selectedHealthSystem = this.healthSystem.find(_hs=>_hs.id == this.facility.healthSystemId).name; 
  
             console.log("facility: " + JSON.stringify(this.facility.healthSystemId));
    
               
          }else{
            this.policies = this._policies.filter(policy => policy.facilityId === this.facility.id && policy.policyTypeId === this._policyTypeId);
              this.selectedHealthSystem = undefined;
          }       
  
      }

      public loadHealthSystem(){
        this.healthSystemService.getHealthSystem()
            .subscribe((healthSystem) => {
                          
                var hSName:HealthSystem[]=[];
                var jsonText = JSON.stringify(healthSystem);             
                var obj =  JSON.parse(jsonText);
                var arr = Object.keys(obj).map(key => ({id: key, name: obj[key]}));   
                      
                this.healthSystemValues= [];  
                arr.forEach(a=>{
                    this.healthSystemValues.push(a.name);
                    hSName.push({
                        id:Number(a.id),
                        name:a.name,
                    });               
               })
               this.healthSystem = hSName;  
        
            });



    }
    public isFacilityInHealthSystem():boolean{
       
        if(!this.facility || (this.facility && !this.facility.healthSystemId)){
             return false;
         }else{
           // console.log('Facility Count:'+this.facilities.length);
         let facilitesInHealthSystem = this.facilities.filter(f=>f.healthSystemId == this.facility.healthSystemId);
         let status:boolean = true;
        //console.log(facilitesInHealthSystem);
         facilitesInHealthSystem.forEach(f=>{
            //console.log('Facility:'+f.name);
            if(!this.userService.isUserAdminAtFacilityToEditAudit(f.id)){
                status= false;
                //console.log(status+":"+f.name);
            }
         });        

            return status;

         }
         
     }
      
}
