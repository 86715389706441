import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Http} from '@angular/http';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateStaticLoader} from 'ng2-translate';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MultiSelectModule} from 'primeng/primeng';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import {MyDatePickerModule} from 'mydatepicker';
import {TooltipModule} from 'primeng/tooltip';

import {UserListComponent} from './user-list.component';
import {UserRoutes} from '../user.route';
import {FacilitySelectorModule} from '../../facility-selector/facility-selector.module';
import {AccountWidgetModule} from '../../account-widget/account-widget.module';
import {UserAddComponent} from '../user-add/user-add.component';
import { RolesInfoDialog } from '../roles-info/roles-info-dialog.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import {DirectiveModule} from '../../shared/directive/directive.module';

import {FilterPipe} from './user-pipes';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ShortDatePipePipe } from './short-date-pipe.pipe';

export function translateLoader(http: Http): TranslateStaticLoader {
    return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
    declarations: [UserListComponent, FilterPipe, RolesInfoDialog, ShortDatePipePipe],
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        RouterModule.forRoot(UserRoutes),
        TranslateModule.forRoot({
            provide: TranslateLoader,
            useFactory: translateLoader,
            deps: [Http],
        }),
        BrowserAnimationsModule,
        MultiSelectModule,
        AngularMultiSelectModule,
        MyDatePickerModule,
        FacilitySelectorModule,
        AccountWidgetModule,
        TooltipModule,
        SimpleModalModule.forRoot({ container: document.body }),
        DirectiveModule,
        NgxSpinnerModule
    ],
    providers: [],
    entryComponents: [UserAddComponent, RolesInfoDialog],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UserListModule {}
