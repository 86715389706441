import {CrudApiService} from "./common/crud.api.service";
import {Policy, IPolicy} from "../model/policy";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import {Observable} from "rxjs";

@Injectable()
export class PolicyService extends CrudApiService<Policy, IPolicy, Partial<Policy>> {

    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/policies");
    }

    public getPolicyByIndex(index: number): Observable<Array<Policy>> {
        return this.get<Array<Policy>>(`/${index}`);
    }
    public getPolicyHistory(): Observable<Array<any>> {
        return this.get<Array<any>>(`/history`);
    }

    //INC0402433 and INC0436439
    //Getting list of policies for an speicific Facility
    public getPolicyList(IdFacility: number): Observable<Array<Policy>>{
        return this.get<Array<Policy>>(`/getpolicieslist/${IdFacility}`);
    }

    //INC0447567
    //Updating active policy from Data Entry
    public updateActivePolicy(IdPolicy: number, policy: Policy): Observable<number>{
        //return this.patch<number>(`/activepolicy/${IdPolicy}`);
        return this.put<number>(`/activepolicy/${IdPolicy}`, policy);
    }

    //INC0447567
    //Validating if the role of the user can change the active policy
    public GetCanActivatePolicy(IdFacility: number): Observable<boolean>{
        return this.get<boolean>(`/canactivepolicy/${IdFacility}`);
    }

    protected assembleSingleInstance(_: any): Policy {
        return new Policy(_);
    }
}
