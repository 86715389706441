import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDatePipe'
})
export class ShortDatePipePipe implements PipeTransform {

  //INC0395359 - Pipe to show a short date in the user list UI
  transform(value: string): string {
    var year = value.substring(0, 4);
    var month = value.substring(4, 6);
    var day = value.substring(6, 8);
    return year +'-'+ month+'-'+day;
  }

}
