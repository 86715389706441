import {IUserRole, UserRole} from "./role";

// Login mechanism does not actually call for User interface - it only has an
// username and password. This will allow to keep User simpler.
export interface LoginCredentials {
    username: string;
    password: string;
}

export interface IUser {
    id?: number;
    username: string;
    firstName: string;
    lastName: string;
    facilityId?: number;
    active?: boolean;
    roles?: Array<IUserRole>;
    termsAccepted?: boolean;
    healthSystemIdList?: Array<number>;
    createdAt?: string; //INC0395359 - Validate creation date
}


export class User implements IUser {
    id?: number;
    username: string;
    firstName: string;
    lastName: string;
    facilityId?: number;
    active?: boolean;
    roles?: Array<UserRole>;
    termsAccepted?: boolean;
    healthSystemIdList?: Array<number>;
    createdAt?: string;

    constructor(args: IUser) {
        console.assert(args !== undefined && args !== null, 'User constructor passed null or undefined args object');
        console.assert(typeof args.username === 'string', 'User constructor passed args with invalid value');
        console.assert(typeof args.firstName === 'string', 'User constructor passed args with invalid value');
        console.assert(typeof args.lastName === 'string', 'User constructor passed args with invalid value');
        if (args.active !== undefined && args.active !== null) {
            console.assert(typeof args.active === 'boolean', 'User constructor passed args with invalid value');
        }
        if (args.id !== undefined && args.id !== null) {
            console.assert(typeof args.id === 'number', 'User constructor passed args with invalid value');
        }
        if (args.termsAccepted !== undefined && args.termsAccepted !== null) {
            console.assert(typeof args.termsAccepted === 'boolean', 'User constructor passed args with invalid value');
        }
        console.assert(Array.isArray(args.roles), 'User constructor passed args with invalid value');
        if (args.healthSystemIdList !== undefined) {
            console.assert(Array.isArray(args.healthSystemIdList), 'User constructor passed args with invalid value');
        }
        this.id = args.id || undefined;
        this.username = args.username;
        this.firstName = args.firstName;
        this.lastName = args.lastName;
        this.active = args.active || undefined;
        this.roles = args.roles.map(_ => new UserRole(_));
        this.termsAccepted = args.termsAccepted || false;
        this.healthSystemIdList = args.healthSystemIdList || undefined;
        this.facilityId = args.facilityId || 0;

        this.createdAt = args.createdAt || '20240101'; //INC0395359 - Validate creation date
    }
}
