import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from '../../shared/service/data.service';
import { NotificationService } from '../../shared/service/notification.service';
import { TokenService } from '../../shared/service/token.service';
import { ErrorService } from '../../shared/service/error-service';
import { UserAddService } from '../user-add/user-add.service';
import { UserRole } from '../../shared/model/role';
import { USER_DETAIL_PAGE as UI_CONSTANTS } from '../../shared/constants/uiConstants';
import { SimpleModalService } from 'ngx-simple-modal';
import { CreateAndEditUserModalDialogMode, UserAddComponent } from '../user-add/user-add.component';
import {
    IAddAndEditUserRoleModalDialogResult,
    AddAndEditUserRoleModalDialogComponent,
    AddAndEditUserRoleModalDialogMode
} from '../add-and-edit-user-role-modal-dialog/add-and-edit-user-role-modal-dialog.component';
import { ROLES } from '../../shared/constants/roles';
import { Route } from '../../shared/constants/index';
import { MODAL_DIALOG_FADE_DURATION_MS } from '../../shared/constants/animationConstants';
import { FacilityService } from '../../shared/api/facility.service';
import { Facility } from '../../shared/model/facility';
import { User } from '../../shared/model/user';
import { RoleService } from '../../shared/api';
import { UserService , HealthSystemService } from '../../shared/api';
import { FacilityWorker, FacilityWorkerRole } from '../../shared/model/facility-worker';
import { Utils } from '../../shared/service/utils';
import { IRoleListingRecord } from '../../shared/interfaces/irolelisringrecord';
import AbstractSkeletonLoader from '../../shared/animations/AbstractSkeletonLoader';
import { trigger, style, animate, transition } from '@angular/animations';
import { HealthSystem } from '../../shared/model/health-system';

import {
    IAddUserHealthSystemModalDialogResult,
    AddUserHealthSystemComponent,
    AddUserHealthSystemModalDialogMode
} from '../add-user-health-system/add-user-health-system';
//import {Util} from '../../util/util';

type RoleAndHealthSystemTab = 'Active' | 'Inactive';


@Component({
    templateUrl: 'user-detail.component.html',
    styleUrls: ['user-detail.component.scss'],
    animations: [
        trigger('opacityFade', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.3s', style({ opacity: 1 })),
            ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('0.3s', style({ opacity: 0 })),
        ])])],
})
export class UserDetailComponent extends AbstractSkeletonLoader implements OnInit {
    public user: User;
    public loggedUser: User;
    public registeredRoles: IRoleListingRecord[] = [];
    public UI = UI_CONSTANTS;
    public selectedTab: RoleAndHealthSystemTab = 'Active';

    private permittedRoleList: UserRole[] = [];
    private permittedFacilityList: Facility[] = [];
    private data: Promise<[Facility[], UserRole[], FacilityWorker[], User]>;
    private __promises: [Promise<Facility[]>, Promise<UserRole[]>, Promise<FacilityWorker[]>, Promise<User>] = <
        [Promise<Facility[]>, Promise<UserRole[]>, Promise<FacilityWorker[]>, Promise<User>]
    >new Array(4);
    public readonly permissions = {
        update: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN, ROLES.ROLE.ROLE_REP]
    };
    public healthSystemValues=[];
    public selectedHealthSystem:string = undefined;
    public healthSystem: HealthSystem []=[];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userAddService: UserAddService,
        private tokenService: TokenService,
        private dataService: DataService,
        private notificationService: NotificationService,
        private errorService: ErrorService,
        private simpleModalService: SimpleModalService,
        private facilityService: FacilityService,
        private roleService: RoleService,
        private userService: UserService,
        private utilsService: Utils,
        private healthSystemService: HealthSystemService,
    ) {
        super();
    }

    public mayCurrentUserEditUser(user: User): boolean {
        //return this.userService.mayActiveUserAdministerUser(user);
        return this.userService.hasPermisssToChangeForCurrentUser(user);
    }

    public ngOnInit() {
        // TODO: Refactor this, the user id should be passed in by the route and
        // the user loaded as any other data. 
        this.refreshUserRecord();    
        
        this.loggedUser = this.userService.getCurrentLoggedUser();

        this.user = this.dataService.user;
        if (this.user === null) {
            console.warn('No user selected in dataService. Returning to user list');
            this.router.navigate([Route.USER]);
        }
        this.__promises[0] = new Promise((resolve, reject) => {
            this.facilityService.getAllEntities().subscribe(
                list => {
                    this.permittedFacilityList = list;
                    resolve(list);
                },
                err => this.errorService.handleErrorStatusCode(err)
            );
        });
        this.__promises[1] = new Promise((resolve, reject) => {
            this.roleService.getAllEntities().subscribe(
                roles => {
                    if (this.userService.getPrimaryRoleForCurrentUser() == ROLES.ROLE.FACILITY_ADMIN) {
                        this.permittedRoleList = roles.filter(r => {
                            return r.role == ROLES.ROLE.AUDITOR || r.role == ROLES.ROLE.FACILITY_ADMIN;
                        });
                    } else if (this.userService.getPrimaryRoleForCurrentUser() == ROLES.ROLE.ADMIN_3M) {
                        this.permittedRoleList = roles.filter(r => {
                            return r.role == ROLES.ROLE.FACILITY_ADMIN || r.role == ROLES.ROLE.AUDITOR || r.role == ROLES.ROLE.ROLE_REP;
                            
                        });
                    } else {
                        //this.permittedRoleList = roles;
                        this.permittedRoleList = roles.filter(r => {
                            return r.role == ROLES.ROLE.SUPER_ADMIN_3M || r.role == ROLES.ROLE.ADMIN_3M || r.role == ROLES.ROLE.FACILITY_ADMIN || r.role == ROLES.ROLE.AUDITOR || r.role == ROLES.ROLE.ROLE_REP;
                    });
                        
                    }
                    resolve(roles);
                },
                err => this.errorService.handleErrorStatusCode(err)
            );
        });
        this.__promises[3] = Promise.resolve(this.dataService.user);
        this._reloadListing();
        this.updateData();
    }

    /**
     * Re-load the needed data, wait for all data to be loaded and then
     * construct the user role listing.
     *
     * The `data` promise can be user to show/enable elements in UI as well.
     */
    private _reloadListing() {
  
       /* this.userService.getAllEntities().subscribe(u=>{ 
           // users=u;
            this.user.healthSystemIdList = u.find(_=>_.id == this.user.id).healthSystemIdList;
            console.log("Reload User:"+JSON.stringify(u.find(_=>_.id == this.user.id)));
            }   
                 
            
        );     
        */
        
        //this.refreshUserRecord();          
        console.log("Reload User Details:"+ JSON.stringify(this.user));  
        this._loadWorkers();
        this.data = Promise.all<Facility[], UserRole[], FacilityWorker[], User>(this.__promises);
        this.showSkeletonAfterDelay();
        this.data.then(_ => {
            this.hideSkeletonAndShowContent();
            this.constructRoleList(_);
        });
    }

    /**
     * (Re)load the worker records for this user.
     */
    private _loadWorkers() {
        this.__promises[2] = this.facilityService.getWorkersForUserId(this.user.id);
    }

    private constructRoleList(p): void {
        const facilities: Facility[] = p[0];
        const roles: UserRole[] = p[1];
        const workers: FacilityWorker[] = p[2];
        const collection: IRoleListingRecord[] = [];

        // List the roles that do not require facility - they can not duplicate
        // and can be listed first
        this.user.roles.forEach(role => {
            if (!this.utilsService.doesRoleRequireFacility(role)) {
                collection.push({
                    facility: null,
                    facilityDisplayName: UI_CONSTANTS.FACILITY_NOT_APPLICABLE,
                    // We do this remapping to allow form to auto find the preselected option,
                    // might be a better way by trakcing objects based on a property instead of
                    // identity...
                    role: this.permittedRoleList.find(_ => _.id === role.id),
                    roleDisplayName: role.description,
                    workerItem: null
                });
            }
        });

        // Ignore the roles that do require facility and instead list the roles
        // using the workers records for the user.
        workers.forEach(record => {
            // TODO: we should still remap the worker role to user role to be
            // able to edit record as dialog works with the userrole.
            // FIXME: converting facilityworker to userrole looses data because
            // we can have two different userroles mapped to the same
            // facilityworker role.
            if(!this.userService.isUserAdminAtFacility(record.facilityId)){
                //this.notificationService.warn('','You are not the Admin for selected facility. Please select any other facility.');
                return;
            }
                
            const facility = facilities.find(_ => _.id === record.facilityId);
            const role = this.getUserRoleForFacilityWorker(record);
            if(facility != null && facility != undefined)
            collection.push({
                role: role || null,
                roleDisplayName: role ? role.description : this.utilsService.mapWorkerFacilityToRoleName(record),
                facility: facility || null,
                facilityDisplayName: facility.name || '',
                workerItem: record
            });
        });

        this.registeredRoles = collection;
    }

    private getUserRoleForFacilityWorker(fw: FacilityWorker): UserRole {
        return this.permittedRoleList.find(_ => {
            if (this.utilsService.doesRoleRequireFacility(_)) {
              
                return this.utilsService.convertUserRoleToWorkerRole(_.role) === fw.role.role;
            }
        });
    }

    public get fullName(): string {
        console.assert(this.user != null, 'UserDetailComponent#get fullName - this.user is null');
        return this.user.firstName + ' ' + this.user.lastName;
    }

    public get emailAddress(): string {
        return this.user.username;
    }

    public get shouldShowEmptyListIndicator(): boolean {
        return this.registeredRoles != null && this.registeredRoles.length === 0;
    }
    public get shouldShowEmptyHealthSystemListIndicator(): boolean {
        return false;
    }

    public get isAddingAndEditingRolesLoaded(): boolean {
       
        return this.registeredRoles != null;
    }
    public isUserRoleAdded(user:User): boolean {
        console.log(this.userService.getPrimaryRoleFromUser(user))
        return this.userService.getPrimaryRoleFromUser(user) == null;
    }

    public get toggleActiveButtonLabel(): string {
        console.assert(
            this.user != null,
            'UserDetailComponent#get toggleActiveButtonLabel - this.currentlyDetailedUser is null'
        );
        if (this.user.active) {
            return UI_CONSTANTS.MAKE_USER_INACTIVE;
        } else {
            return UI_CONSTANTS.MAKE_USER_ACTIVE;
        }
    }

    public get permissionTooltipToModifyUser(): string {
        console.assert(
            this.user != null,
            'UserDetailComponent#get permissionTooltipToModifyUser - this.currentlyDetailedUser is null'
        );
        if (this.user && this.mayCurrentUserEditUser(this.user)) {
            return '';
        } else {
            return UI_CONSTANTS.NO_PERMISSION_TO_MODIFY_USER;
        }
    }

    public setUserActiveFlagOnBackend(user: User, makeActive: boolean) {
        user.active = makeActive;
        //INC0470671 - Modifying request to comply with security policies. Sending whole object.
        this.userService
            .updateEntity(user.id, user/*{active: makeActive}*/)
            .subscribe(
                () => {
                    this.notificationService.success(
                        '',
                        makeActive ? UI_CONSTANTS.SUCCESS.MAKE_ACTIVE : UI_CONSTANTS.SUCCESS.MAKE_INACTIVE
                    );
                },
                err => this.errorService.handleErrorStatusCode(err)
            );
    }

    public onEditUserPressed(): void {
        console.assert(this.user != null, 'UserListComponent#onEditUserPressed - this.currentlyDetailedUser was null');
        this.simpleModalService
            .addModal(
                UserAddComponent,
                { mode: CreateAndEditUserModalDialogMode.Edit },
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS
                }
            )
            .subscribe(result => {
                if (result && result.needsRefresh && result.modifiedUser) {
                    this.user = result.modifiedUser;
                    this.updateUserRecord();
                }
            });
    }

    public onToggleActivePressed(): void {
        console.assert(
            this.user != null,
            'UserDetailComponent#onToggleActivePressed - this.currentlyDetailedUser was null'
        );
        console.assert(
            this.user.active != undefined,
            'UserDetailComponent#onToggleActivePressed - this.currentlyDetailedUser.activeFlag was undefined'
        );
        this.user.active = !this.user.active; // Optimistically update UI
        this.setUserActiveFlagOnBackend(this.user, this.user.active);
    }

    /**
     * Pre-filter the roles the user can have in the modal UI.
     *
     * We only allows roles that are not assigned or that are assigned more than
     * once (like the roles linked to a specific facility).
     *
     */
    private filteRolesToGetOnlyAllowed() {
        return this.permittedRoleList.filter(_ => {
            if (String(_.role) !== 'ROLE_SUPER_ADMIN' && String(_.role) !== 'ROLE_ADMIN') {
                return true;
            }
            const hasThisRole = this.user.roles.find(assignedrole => {
                return assignedrole.id === _.id;
            });
            if (hasThisRole) {
                return false;
            }
            return true;
        });
    }

    /**
     * Start role adding for the current user.
     * The dialog will be pre-filtered to only allow valid combinations to be
     * added.
     */
    public onAddRolePressed(): void {
        console.assert(this.user != null, 'UserDetailComponent#onAddRolePressed - this.currentlyDetailedUser was null');
        console.assert(
            this.user.roles != null,
            'UserDetailComponent#onAddRolePressed - this.currentlyDetailedUser.role was null'
        );
        this.simpleModalService
            .addModal(
                AddAndEditUserRoleModalDialogComponent,
                {
                    mode: AddAndEditUserRoleModalDialogMode.Add,
                    selectedRole: undefined, // this.currentlyDetailedUser.roles[0].id,
                    selectedFacility: undefined,
                    initialFacility: undefined,
                    permittedRoleList: this.filteRolesToGetOnlyAllowed(),
                    permittedFacilityList: this.permittedFacilityList,
                    registeredRoles: this.registeredRoles
                },
                { animationDuration: MODAL_DIALOG_FADE_DURATION_MS }
            )
            .subscribe(_ => this.handleRoleAddModalData(_));
    }

    public onAddHealthSystemPressed(): void {
        console.assert(this.user != null, 'UserDetailComponent#onAddRolePressed - this.currentlyDetailedUser was null');
        console.assert(
            this.user.roles != null,
            'UserDetailComponent#onAddRolePressed - this.currentlyDetailedUser.role was null'
        );
        this.simpleModalService
            .addModal(
                AddUserHealthSystemComponent,
                {
                    mode: AddUserHealthSystemModalDialogMode.Add,                    

                },
                { animationDuration: MODAL_DIALOG_FADE_DURATION_MS }
            )
            .subscribe(_ => this.handleUserHealthSystemAddModalData(_));
    }

    private handleUserHealthSystemAddModalData(result: IAddUserHealthSystemModalDialogResult): void {
        if (result !== undefined) {

            //need to update health system list for the user
            console.log(result.modifiedUser); 
            
        }
    }
    /**
     * Extracted handler for updating server records with a new role for
     * the currently edited user.
     */
    private handleRoleAddModalData(result: IAddAndEditUserRoleModalDialogResult): void {
        if (result !== undefined) {
            // In common case we need to only update the user on the server
            // Also in the modal data we made sure that the user did not
            // have this role or that the role can be duplicated.


            if (this.utilsService.doesRoleRequireFacility(result.selectedRole)) {
                console.assert(result.selectedFacility != null, 'UserDetailComponent#handleRoleAddModalData - Selected role requires facility but there is no selected facility in the dialog result.');
            }

            // If the role requires a worker record, make that as well.
            if (!!result.selectedFacility) {
                const facilityWorker = new FacilityWorker({
                    facilityId: result.selectedFacility.id,
                    userId: this.user.id,
                    role: new FacilityWorkerRole({
                        role: this.utilsService.convertUserRoleToWorkerRole(result.selectedRole.role)
                    })
                });

                // TODO: Make something with the result, either re-request the list of
                // workers or add this one to the list.
                this.facilityService.createWorker(result.selectedFacility.id, facilityWorker).then(() => {
                    if (!this.user.roles.find(_ => _.id === result.selectedRole.id)) {
                        this.user.roles = [result.selectedRole, ...this.user.roles];
                        return this.updateUserRecord().then(() => {
                            this._reloadListing();
                        });
                    } else {
                        this._reloadListing();
                    }
                })
                .catch(err => {
                    this.errorService.handleErrorStatusCode(err);
                });
            } else {
                if (!this.user.roles.find(_ => _.id === result.selectedRole.id)) {
                    this.user.roles = [result.selectedRole, ...this.user.roles];
                    this.updateUserRecord().then(() => {
                        this._reloadListing();
                    })
                    .catch(err => {
                        this.errorService.handleErrorStatusCode(err);
                    });
                } else {
                    this._reloadListing();
                }
            }
        }
    }

    public onEditRolePressed(record: IRoleListingRecord) {
        // We must have one role assigned to the user at least
        const canDeleteRole: boolean = this.registeredRoles.length > 1;
        this.simpleModalService
            .addModal(
                AddAndEditUserRoleModalDialogComponent,
                {
                    mode: AddAndEditUserRoleModalDialogMode.Edit,
                    initialFacility: record.facility,
                    selectedFacility: record.facility,
                    selectedRole: record.role,
                    permittedFacilityList: this.permittedFacilityList,
                    permittedRoleList: this.permittedRoleList,
                    registeredRoles: this.registeredRoles,
                    canDelete : canDeleteRole,
                },
                { animationDuration: MODAL_DIALOG_FADE_DURATION_MS }
            )
            .subscribe((result: IAddAndEditUserRoleModalDialogResult) => {
                if (result == undefined) {
                    return;
                }
                const promises = [];
                if (result.shouldDelete === true) {
                    // check if we should update the user...
                    if (this.utilsService.doesRoleRequireFacility(record.role)) {
                        promises.push(this.facilityService.deleteWorker(record.workerItem));

                        // Role can be duplicated, check if we have at least one more use of this role in
                        // worker records.
                        const filteredRoles = this.registeredRoles.filter(registeredRole => {
                            return registeredRole.workerItem
                                && registeredRole.workerItem.role
                                && registeredRole.workerItem.role.role === record.workerItem.role.role
                        });
                        if (filteredRoles.length < 2) {
                            this.user.roles = this.user.roles.filter(_ => _.id !== record.role.id);
                            promises.push(this.updateUserRecord());
                        }
                    } else {
                        console.log(this.user.roles);
                        console.log(record);
                        // Remove the role from the user, as it cannot be duplicated and deletion is required.
                        this.user.roles = this.user.roles.filter(_ => _.id !== record.role.id);
                        promises.push(this.updateUserRecord());
                    }
                } else {
                    const roleHasChanged: boolean = result.selectedRole.id !== record.role.id;
                    let userShouldUpdate: boolean = false;
                    let facilityHasChanged: boolean = false;

                    // Determine if facility record has changed and we should update
                    // if both are null - no
                    // if one was null but the other not - we need update
                    // if both are existing but different ids - also needs update.
                    if (record.facility === null) {
                        if (result.selectedFacility !== null) {
                            facilityHasChanged = true;
                        }
                    } else {
                        // we had a facility initially, check if it the same.
                        if (result.selectedFacility === null) {
                            facilityHasChanged = true;
                        } else {
                            facilityHasChanged = result.selectedFacility.id !== record.facility.id;
                        }
                    }

                    const worker = record.workerItem;
                    // Determine if the role has changed.
                    if (roleHasChanged) {
                        if (this.utilsService.doesRoleRequireFacility(record.role)) {
                            const rolesWithWorker = this.registeredRoles.filter(_ => _.workerItem != null);
                            const len = rolesWithWorker.filter(
                                _ => _.workerItem.role.role === record.workerItem.role.role
                            ).length;
                            if (len < 2) {
                                this.user.roles = this.user.roles.filter(_ => _.id !== record.role.id);
                                userShouldUpdate = true;
                            }

                            if (this.utilsService.doesRoleRequireFacility(result.selectedRole)) {
                                if (result.selectedRole.role === "ROLE_AUDITOR") {
                                    worker.role.role = 'AUDITOR';
                                } else if (result.selectedRole.role === "ROLE_FACILITY_ADMIN") {
                                    worker.role.role = 'ADMIN';
                                }else if (result.selectedRole.role === "ROLE_REP") {
                                    worker.role.role = 'REP';
                                }
                                promises.push(this.facilityService.updateWorker(worker));
                            }
                        } else {
                            userShouldUpdate = true;
                            this.user.roles = this.user.roles.filter(_ => _.id !== record.role.id);
                        }
                        if (!this.user.roles.find(role => role.id === result.selectedRole.id)) {
                            userShouldUpdate = true;
                            this.user.roles.push(result.selectedRole);
                        }
                        if (userShouldUpdate) {
                            promises.push(this.updateUserRecord());
                        }
                    }

                    if (facilityHasChanged) {
                        // New role does not have a facility, delete the old record.
                        if (result.selectedFacility === null && record.facility !== null) {
                            promises.push(this.facilityService.deleteWorker(record.workerItem));
                        } else if (result.selectedFacility !== null && record.facility === null) {
                            // Old role had no facility but new one does, create record
                            promises.push(
                                this.facilityService.createWorker(
                                    result.selectedFacility.id,
                                    new FacilityWorker({
                                        userId: this.user.id,
                                        facilityId: result.selectedFacility.id,
                                        role: new FacilityWorkerRole({
                                            role: this.utilsService.convertUserRoleToWorkerRole(
                                                result.selectedRole.role
                                            )
                                        })
                                    })
                                )
                            );
                        } else {
                            // Update existing record.
                            worker.facilityId = result.selectedFacility.id;
                            worker.role = new FacilityWorkerRole({
                                role: this.utilsService.convertUserRoleToWorkerRole(result.selectedRole.role)
                            });
                            // Updating a worker to a new facilityId isn't working at the moment, so delete and create instead.
                            // Either the worker isn't found (404 error worker not found) because we're trying to update at the new facilityId or if I override that it doesn't error but doesn't change.
                            // promises.push(this.facilityService.updateWorker(worker));

                            const promise = this.facilityService.updateWorker(worker);
                            promises.push(promise);
                        }
                    }
                }
                Promise.all(promises).then(() => this._reloadListing())
                    .catch(err => this.errorService.handleErrorStatusCode(err));
            });
    }

    /**
     * Helper to update the user record on the server.
     * Because of how code is structired this happens only when we edit user
     * role, but instead it should happen when we also edit the user.
     *
     * TODO: resuse this logic fall all modals in this component.
     */
    private updateUserRecord(): Promise<User> {
        return new Promise((resolve, reject) => {
            this.userService.updateEntity(this.user.id, this.user).subscribe(
                _ => resolve(_),
                _ => {
                    this.errorService.handleErrorStatusCode(_);
                    reject(_);
                }
            );
        }).then((_: User) => {
            this.user = _;
            this.dataService.user = this.user;
            return _;
        });
    }

    public selectTab(tab: RoleAndHealthSystemTab): void {
        this.selectedTab = tab;
        console.log(tab);
    }

    public isTabSelected(tab: RoleAndHealthSystemTab): boolean {
        return this.selectedTab === tab;
    }

    public updateData(): void {
        //this.showSkeletonAfterDelay(10);
        const loadTasks: Promise<void>[] = [this.LoadHealtSystem()];
        Promise.all(loadTasks).then(() => {
           // this.hideSkeletonAndShowContent();
        });

    }
    
    public LoadHealtSystem(): Promise<void> {
        return new Promise(resolve => {
            this.healthSystemService.getHealthSystem()
             .subscribe((healthSystem) => {
                           
                 var hSName: HealthSystem[]=[];   
                 var jsonText = JSON.stringify(healthSystem);             
                 var obj =  JSON.parse(jsonText);
                 var arr = Object.keys(obj).map(key => ({id: key, name: obj[key]}));                          
                
                 arr.forEach(a=>{ 
                    if(this.user.healthSystemIdList != undefined){                

                        if(this.user.healthSystemIdList.filter(_=>_ == Number(a.id)).length > 0){
                        hSName.push({
                            id:Number(a.id),
                            name:a.name,
                        });   
                        }  
                    }
                })  

                this.healthSystem = hSName;

                /*if(this.isUserAdmin()){
                   // console.log("User1:"+this.userService.getPrimaryRoleForCurrentUser()); 
                    this.healthSystem = hSName;
                }else{
                    if(this.loggedUser.healthSystemIdList != undefined){
                        this.healthSystem = hSName.filter(_=>{
                            return this.userService.getCurrentLoggedUser().healthSystemIdList.includes(_.id);
                        });
                    }
                }    */
         
            });
        });          
  
    }
    public disableAddHealthSystemButton():boolean{

        if(this.isUserAdmin()){

            return false;
        }else{

            if(this.loggedUser.healthSystemIdList == undefined){
                return true;
            }
        }

        return false
    }

    public onRemoveHealthSystemPressed(healthSystemId:number){

        
       // console.log("Removing Health System "+healthSystemId+" from User");
       // this.notificationService.success('', "Removed - Health System "+healthSystemId+" from User");
        this.removePressed(healthSystemId);
    }
    public removePressed(healthSystemId:number){     
        const promises = [];     
        promises.push(new Promise((resolve, reject) => {
            this.healthSystemService.removeHealthSystemToUser(this.user.id, healthSystemId).subscribe(
                _ => resolve(null),
                _ => reject(_));
        }));

        Promise.all(promises).then(_ => {
            //this.isAPICalled = false;
            this.notificationService.success('', UI_CONSTANTS.SUCCESS.USER_MODIFIED);
            //this.result = { needsRefresh: true, modifiedUser: this.user };
            //this.refer();
            this.updateUserRecord().then(() => {    
                this.router.navigate([Route.USER]);            
                this._reloadListing();
                this.updateData();
            });
            
        }).catch(_ => {
            //this.isAPICalled = false;
           // this.handleResponseError(_);
        });
    }
    private refreshUserRecord(): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this.userService.getAllEntities().subscribe(
                _ => resolve(_),
                _ => {
                    this.errorService.handleErrorStatusCode(_);
                    reject(_);
                }
            );
        }).then((_: User[]) => {
            this.user = _.find(u=>u.id == this.user.id);
            this.dataService.user = this.user;
            return _;
        });
    }
    public isUserAdmin():boolean{

        const role = this.userService.getPrimaryRoleForCurrentUser();  
        //console.log("Role:"+JSON.stringify(role));
        if (role === ROLES.ROLE.SUPER_ADMIN_3M || role === ROLES.ROLE.ADMIN_3M ) {

            return true;
        }
        return false;

    }
}
