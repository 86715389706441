import {Injectable} from '@angular/core';
import {IMAGES} from '../../constants/index';
import {ReportContext} from './report.context';
import {ComprehensiveType, TwentyOneDayChallengeType, SingleMetricType, ViewTab} from '../../constants/reports';
import * as moment from 'moment';
import {TimeShift, GetDisplayStringForTimeShift} from '../../model/time.shift';
import { UnitReport } from '../../model/unit.report';
import { Policy } from '../../model/policy';
import { QuestionAnswer } from '../../model/question-answer';
import { ReportType } from '../../model/report.type';
import { isDefined } from '@angular/compiler/src/util';

declare var require: any;
const pptxgenjs = require('pptxgenjs');
interface PolicyResponse {
    question: string;
    answer: string[];
  
}
const CONSTANTS = {
    FONTS: {
        BOLD: '3MCircularTT-Bold',
        NORMAL: '3MCircularTT-Book',
    },
    MAX_ROWS_PER_PAGE: 6,
    MAX_COLUMNS_PER_PAGE: 7,
    SLIDE_MASTER: {
        title: 'CONTENT_SLIDE',
        bkgd: 'FFFFFF',
    },
};

declare interface PptxGenJS {
    addNewSlide(inMasterName?: string, opts?: SlideParams): Slide;

    save(fileName: string): void;

    setBrowser(value: boolean);

    defineSlideMaster(bkgd: any);
}

declare interface Slide {
    addText(value: string, options: SlideOptions);
    addText(elements: TextElement[], opts: SlideOptions);
    addImage(image: SlideImage);
    addTable(data: any[], table: SlideTableOptions);
}

interface SlideParams {
    bkgd?: string;
}

type Point = number | string;

interface SlideElement {
    apply(slide: Slide): void;
}

interface TextElement {
    text: string;
    options: {
        fontSize?: number;
        fontFace?: string;
        color?: string;
        align?: string;
        breakLine?: boolean,
        bold?: boolean;
        autoFit?: boolean;
    };
}

interface SlideOptions {
    x?: Point;
    y?: Point;
    w?: Point;
    h?: string | number;
    fontSize?: number;
    fontFace?: string;
    color?: string;
    align?: string;
    margin?: number;
    breakLine?: boolean;
    lineSpacing?: number;
    bold?: boolean;
}

interface SlideTableOptions extends SlideOptions {
    autoPage?: boolean;
    lineWeight?: number;
    newPageStartY?: number;
}

class SlideText implements SlideElement {
    constructor(private value: string,
                private params: SlideOptions) {
    }

    apply(slide: Slide): void {
        slide.addText(this.value, this.params);
    }
}

class SlideImage implements SlideElement {
    data: string;
    x?: Point;
    y?: Point;
    w?: Point;
    h?: Point;

    constructor(data: string, options?: SlideOptions) {
        this.data = data;
        if (options) {
            this.x = options.x;
            this.y = options.y;
            this.w = options.w;
            this.h = options.h;
        }
    }

    apply(slide: Slide): void {
        slide.addImage(this);
    }
}

class SlideTable implements SlideElement {

    constructor(private data: any[][],
                private options: SlideTableOptions) {
    }

    apply(slide: Slide): void {
        slide.addTable(this.data, this.options);
    }
}

interface SlideTableCell {
    text: string;
    options?: SlideTableCellOptions;
}

interface SlideTableCellOptions {
    fill?: string;
    fontSize?: number | string;
    fontFace?: string;
    color?: string;
    rowH?: number;
    valign?: string;
    align?: string;
    border?: any;
}

@Injectable()
export class PptService {
    private static dateObjectToString(date: Date): string {
        return moment(date).format('MM/DD/YYYY');
    }

    public healthSystem: string = undefined;


    constructor() {}

    public exportReport(reportContext: ReportContext, fileName: string) {
        const pptx: PptxGenJS = new pptxgenjs();
        pptx.setBrowser(true);
        pptx.defineSlideMaster(CONSTANTS.SLIDE_MASTER);

        const currentDateString = PptService.dateObjectToString(new Date());

        let subheadingText = '';

        if (reportContext.reportView != null) {
            subheadingText = reportContext.reportView.title;
        }
        if (reportContext.reportMetric != null) {
            subheadingText = reportContext.reportMetric.name;
        }

        this.formatTitleSlide(pptx.addNewSlide(), reportContext, subheadingText, currentDateString);
        if(reportContext.data!==null&&reportContext.data.header!=null)
        this.addTableSlides(pptx, reportContext);
        this.addCommentsSlide(pptx, reportContext);

        pptx.save(fileName);
    }

    public exportAllReports(masterReportContext: ReportContext, fileName: string, questionResponse: Array<PolicyResponse>, selectedPolicy: Policy, healthSystem?: string) {
        const pptx: PptxGenJS = new pptxgenjs();
        pptx.setBrowser(true);
        pptx.defineSlideMaster(CONSTANTS.SLIDE_MASTER);
        this.healthSystem = healthSystem;
        const currentDateString = moment(new Date()).format('MM/DD/YYYY');
        console.log("h:"+healthSystem);//return;
        this.formatTitleSlide(pptx.addNewSlide(), masterReportContext, 'All Reports', currentDateString);

        this.addAllTablesForReportTypeInReportContext(pptx, masterReportContext); 
        this.addCommentTAtEndPPT(pptx, masterReportContext);     
        this.AddPolicyQuestions(masterReportContext, pptx, questionResponse, selectedPolicy); 
       // this.addCommentsSlide(pptx, masterReportContext);
        pptx.save(fileName);

    }
    public exportAllShiftReports(masterReportContextDay: ReportContext,masterReportContextNight: ReportContext,masterReportContextBoth: ReportContext, fileName: string, questionResponse: Array<PolicyResponse>, selectedPolicy: Policy, healthSystem?:string) {
        const pptx: PptxGenJS = new pptxgenjs();
        pptx.setBrowser(true);
        pptx.defineSlideMaster(CONSTANTS.SLIDE_MASTER);

        const currentDateString = moment(new Date()).format('MM/DD/YYYY');
        this.healthSystem = healthSystem;
       
        console.log("masterReportContextBoth"+JSON.stringify(masterReportContextBoth));
        this.formatTitleSlide(pptx.addNewSlide(), masterReportContextDay, 'All Reports', currentDateString);
        this.addAllTablesForReportTypeInReportContext(pptx, masterReportContextBoth);
        this.addAllTablesForReportTypeInReportContext(pptx, masterReportContextDay);    
        this.addAllTablesForReportTypeInReportContext(pptx, masterReportContextNight);  
        this.addCommentTAtEndPPT(pptx, masterReportContextBoth);
        this.AddPolicyQuestions(masterReportContextBoth, pptx, questionResponse, selectedPolicy);
        pptx.save(fileName);

    }

    AddPolicyQuestions(masterReportContex: ReportContext, pptx: PptxGenJS, questionResponse: Array<PolicyResponse>, selectedPolicy: Policy) {
      
        this.addTableSlideAuditPolicy(pptx, masterReportContex, "Audit Policy", 0, 0, 6, questionResponse, selectedPolicy )
    }

    private addAllTablesForReportTypeInReportContext(pptx: PptxGenJS, masterReportContext: ReportContext) {
        const {reportType, policy, facility, fullReport, reportMetric, fromDate, toDate, timeShift, reportView} = masterReportContext; 

        if (reportType instanceof ComprehensiveType) {
            const savedSelectedView = reportType.getSelectedView();

            for (const view of reportType.views) {
               // console.log(JSON.stringify(view));
                reportType.selectView(view);
            
                const tabs = (view.tabs != null && view.tabs.length > 0) ? view.tabs : [null];
                const savedSelectedTab = view.getSelectedTab();
    
                for (const tab of tabs) {
                    view.selectTab(tab);

                    const table = reportType.prepare({data: fullReport, metric: reportMetric, view});

                    const reportContext: ReportContext = new ReportContext(
                        reportType,
                        policy,
                        facility,
                        table,
                        fullReport,
                        fromDate,
                        toDate,
                        timeShift,
                        view,
                        reportMetric,
                    );
                    // "Reference Info / Disinfecting Port Protectors / Tubing / Custom Metrics / Catheter / IV Dressings/ Catheter / IV Dressings/ Catheter / IV Dressings"
                    //need to optimize the loop
                    if(reportContext.data!==null&&reportContext.data.header!=null){
                        //console.log(masterReportContext.fullReport);
                        this.addTableSlides(pptx, reportContext);                  
                    }
                }

                view.selectTab(savedSelectedTab);
            }

            reportType.selectView(savedSelectedView);
        } else if (reportType instanceof TwentyOneDayChallengeType) {
            const table = reportType.prepare({data: fullReport, metric: reportMetric, view: reportView});

            const reportContext: ReportContext = new ReportContext(
                reportType,
                policy,
                facility,
                table,
                fullReport,
                fromDate,
                toDate,
                timeShift,
                reportView,
                reportMetric,
            );
            if(reportContext.data!==null&&reportContext.data.header!=null)
            this.addTableSlides(pptx, reportContext);
  
            } else if (reportType instanceof SingleMetricType) {

        } else {
            console.assert(false, 'This report type is not currently supported.');
        }
    }

    private addCommentTAtEndPPT(pptx: PptxGenJS, masterReportContext: ReportContext) {
        const {reportType, policy, facility, fullReport, reportMetric, fromDate, toDate, timeShift, reportView} = masterReportContext; 
        if (reportType instanceof ComprehensiveType) {
            const savedSelectedView = reportType.getSelectedView();

            for (const view of reportType.views) {
               // console.log(JSON.stringify(view));
                reportType.selectView(view);
            
                const tabs = (view.tabs != null && view.tabs.length > 0) ? view.tabs : [null];
                const savedSelectedTab = view.getSelectedTab();
    
                for (const tab of tabs) {
                    view.selectTab(tab);

                    const table = reportType.prepare({data: fullReport, metric: reportMetric, view});

                    const reportContext: ReportContext = new ReportContext(
                        reportType,
                        policy,
                        facility,
                        table,
                        fullReport,
                        fromDate,
                        toDate,
                        timeShift,
                        view,
                        reportMetric,
                    );
                    // "Reference Info / Disinfecting Port Protectors / Tubing / Custom Metrics / Catheter / IV Dressings/ Catheter / IV Dressings/ Catheter / IV Dressings"
                    //need to optimize the loop
                    if(reportContext.data!==null&&reportContext.data.header!=null){
                        //console.log(masterReportContext.fullReport);              
                        if(tab != null){
                        if(tab.title == "IV Dressings"){
                            var isCommentThere= false;

                            masterReportContext.fullReport.unitReports.forEach((element)=>{
                                element.comments.forEach(arrayComment=>{
                                    if(arrayComment != undefined){
                                       
                                        var comments = arrayComment.split("||");
                                        for(var i = 4; i < comments.length; i++){
                                            if(comments[i] == undefined || comments[i]== "")
                                            continue;

                                            isCommentThere = true;
                                        }
                                    }
                                });

                            })

                            if(isCommentThere){
                                this.addTableSlidesComments(pptx, "", 1, masterReportContext);
                                
                                console.log(masterReportContext);
                            }
                        }
                        else if(tab.title == "Disinfecting Port Protectors & Tubing"){

                            var isCommentThere= false;

                            masterReportContext.fullReport.unitReports.forEach((element)=>{
                                element.comments.forEach(arrayComment=>{
                                    if(arrayComment != undefined){
                
                                    var comments = arrayComment.split("||");
                                    for(var i = 1; i < 4; i++){
                                        if(comments[i] == undefined || comments[i]== "")
                                        continue;

                                        isCommentThere = true;
                                    }
                                    }
                                });

                            })

                            if(isCommentThere){
                            this.addTableSlidesComments(pptx, "", 0, masterReportContext); 
                            console.log(masterReportContext);   
                        }}
                        }
                        else{
                            var isMasterComment = 
                            tabs.filter(x => x != undefined && ( x.title == "Disinfecting Port Protectors & Tubing" || x.title == "IV Dressings"));

                            if(isMasterComment && isMasterComment.length > 0)
                            {
                                this.addTableSlidesComments(pptx, "", 2, masterReportContext);     
                                console.log(masterReportContext);
                            }                      
                        }
                    }
                }

                view.selectTab(savedSelectedTab);
            }

            reportType.selectView(savedSelectedView);
        } else if (reportType instanceof TwentyOneDayChallengeType) {
            const table = reportType.prepare({data: fullReport, metric: reportMetric, view: reportView});

            const reportContext: ReportContext = new ReportContext(
                reportType,
                policy,
                facility,
                table,
                fullReport,
                fromDate,
                toDate,
                timeShift,
                reportView,
                reportMetric,
            );
  
            var isCommentThere= false;
            
            masterReportContext.fullReport.unitReports.forEach((element)=>{
            element.comments.forEach(arrayComment=>{
            if(arrayComment != undefined){
                
                // var comments = arrayComment.split("||");
                // for(var i = 4; i < comments.length; i++){
                // if(comments[i] == undefined || comments[i]== "")
                // continue;
                
                isCommentThere = true;
                //}
            }
            });
            
            })
            if(isCommentThere){
            this.addTableSlidesComments(pptx, "", -1, masterReportContext);
            console.log(masterReportContext);
            }
         } else if (reportType instanceof SingleMetricType) {

        //     for (const metric of reportType.metrics) {
        //         const table = reportType.prepare({data: fullReport, metric, view: reportView});

        //         const reportContext: ReportContext = new ReportContext(
        //             reportType,
        //             policy,
        //             facility,
        //             table,
        //             fullReport,
        //             fromDate,
        //             toDate,
        //             timeShift,
        //             reportView,
        //             metric,
        //         );
        //         if(reportContext.data!==null&&reportContext.data.header!=null)
        //         this.addTableSlides(pptx, reportContext);
        //     }
        } else {
            console.assert(false, 'This report type is not currently supported.');
        }
    }

    private addCommentsSlide(pptx: PptxGenJS, reportContext: ReportContext) {
        const comments = reportContext.fullReport.unitReports
            .map(r => r.comments || [])
            .reduce((acc, arr) => [...acc, ...arr], []);
        //console.log(JSON.stringify(reportContext));

        if (comments.length > 0) {

            const maxComment = comments.map(c => c.length)
                .reduce((prev, curr) => prev > curr ? prev : curr, 0);

            const initial = {
                x: 0.5, y: 0.5
            };

            const lineHeigth = 0.5;
            const maxSlideHeight = 5;

            let currY = initial.y;

            let slide = pptx.addNewSlide('CONTENT_SLIDE');
            slide.addText('Comments:', {x: initial.x, y: initial.y, h: lineHeigth, bold: true});
            currY += lineHeigth * 2;
            
            for (const comment of comments) {
                const lineCount = Math.ceil(comment.length / maxComment);
                const h = lineCount * lineHeigth;

                if (currY + h > maxSlideHeight) {
                    currY = initial.y;
                    slide = pptx.addNewSlide('CONTENT_SLIDE');
                }
                        
                let commentReplaced = comment.replace(/\|\|\|\|/g,'||');
                commentReplaced = commentReplaced.replace(/\|\|/g,', ');
                slide.addText(commentReplaced, {x: initial.x, y: currY, h: h, fontSize: 12});
                currY = currY + h + lineHeigth;
            }
        }
    }

    private addTableSlides(pptx: PptxGenJS, reportContext: ReportContext) {
        // Figure out how many pages we need to fit the content. The first row and column are special thus the -1
        const columnPagesCount = Math.ceil((reportContext.data.header.length - 1) / CONSTANTS.MAX_COLUMNS_PER_PAGE);
        const rowPagesCount = Math.ceil((reportContext.data.rows.length) / CONSTANTS.MAX_ROWS_PER_PAGE);

        let maxColumnsPerPage = reportContext.data.header.length;
        if (columnPagesCount > 1) {
            // If there are more than MAX_COLUMNS_PER_PAGE, spread the columns out more or less equally rather than potentially having ten columns on page
            // one and one on page two.
            maxColumnsPerPage = Math.ceil(reportContext.data.header.length / columnPagesCount);
        }

        for (let rowPage = 0; rowPage < rowPagesCount; rowPage++) {
            const rowGroupText = rowPagesCount > 1 ? `Row Group ${rowPage + 1} of ${rowPagesCount}` : '';
            for (let columnPage = 0; columnPage < columnPagesCount; columnPage++) {
                const columnGroupText = columnPagesCount > 1 ? `Column Group ${columnPage + 1} of ${columnPagesCount}` : '';
                let groupText: string = rowGroupText;
                // Set groupText to show any row or column groups, like 'Row Group 1 of 3'
                if (groupText != '') {
                    if (columnGroupText != '') {
                        groupText = `${groupText}, ${columnGroupText}`;
                    }
                } else {
                    groupText = columnGroupText;
                }

                this.addTableSlide(pptx,
                    reportContext,
                    groupText,
                    rowPage * CONSTANTS.MAX_ROWS_PER_PAGE, // The reason we add one is because we handle the first row, the header, differently
                    columnPage * maxColumnsPerPage + 1, // The reason we add one is because we handle the first column, unit name, differently
                    maxColumnsPerPage,
                );
            }
        }
    }

    private addTableSlidesComments(pptx: PptxGenJS, groupText: string, repType: number, reportContext: ReportContext) {
        // Figure out how many pages we need to fit the content. The first row and column are special thus the -1

        
                this.addTableSlideComments(pptx,
                            groupText,
                            1, // The reason we add one is because we handle the first row, the header, differently
                            1, // The reason we add one is because we handle the first column, unit name, differently
                            4,
                            repType,
                            reportContext
                        );
    }

    private addTableSlide(pptx: PptxGenJS, reportContext: ReportContext, groupText: string, rowStart: number, columnStart: number, maxColumns: number) {
        const slide = this.formatTableSlide(pptx.addNewSlide('CONTENT_SLIDE'), reportContext);

        if (groupText !== '') {
            this.fillSlide(slide, new SlideText(groupText, {
                x: 0.25,
                y: 0.55,
                fontSize: 10,
                fontFace: CONSTANTS.FONTS.BOLD,
                color: '696C76',
                align: 'left', // This should be right aligned and placed in the upper right, but right align seems broken
            }));
        }
    
        const table = this._prepareTableData(reportContext, rowStart, columnStart, maxColumns);
        this.fillSlide(slide, new SlideTable(table, {x: 0.325, y: 1.6, w: 9.275, fontFace: CONSTANTS.FONTS.NORMAL, autoPage: false}));
    }
    private addTableSlideComments(pptx: PptxGenJS, groupText: string, rowStart: number, columnStart: number, maxColumns: number, repType: number, reportContext: ReportContext) {
        var unitElemnt : UnitReport[] = [];
        reportContext.fullReport.unitReports.forEach(reportComments => {
            
            reportComments.comments.forEach(elementRaw => {
                var element= elementRaw.replace(/(\r\n|\n|\r)/gm, " ");
             console.log(element);
             console.log("**");
                if(/[a-zA-Z]/g.test(element)){   
                        const unitCommentElement : UnitReport= {
                            unit : reportComments.unit,
                            catheterSites : reportComments.catheterSites,
                            date : reportComments.date,
                            patients : reportComments.patients,
                            ports : reportComments.ports,
                            values : reportComments.values,
                            comments : [element]
                        };
                        console.log(element);
                            unitElemnt.push(unitCommentElement);
                }
            });
    
        });
while(unitElemnt.length > 0)
       { var printElement : UnitReport[] = [];
        if(unitElemnt.length > 4){
            printElement = unitElemnt.splice(0,4);
            
        }else{
            printElement = unitElemnt.splice(0,unitElemnt.length);
        }
        const table = this._prepareTableDataComments(rowStart, columnStart, maxColumns, repType, printElement);
        if(table.length > 1 ){
            const slide = this.formatTableSlide(pptx.addNewSlide('CONTENT_SLIDE'), reportContext,true);
        
            if (groupText !== '') {
                this.fillSlide(slide, new SlideText(groupText, {
                    x: 0.25,
                    y: 0.55,
                    fontSize: 10,
                    fontFace: CONSTANTS.FONTS.BOLD,
                    color: '696C76',
                    align: 'left', // This should be right aligned and placed in the upper right, but right align seems broken
                }));
            }
        
            //console.log("Table Length:"+table.length);   
            this.fillSlide(slide, new SlideTable(table, {x: 0.325, y: 1.6, w: 9.275, fontFace: CONSTANTS.FONTS.NORMAL, autoPage: false}));
        }
    
    }
    
    }

    private addTableSlideAuditPolicy(pptx: PptxGenJS, reportContext: ReportContext, groupText: string, rowStart: number, columnStart: number, maxColumns: number, auditQA : Array<PolicyResponse>, selectedPolicy: Policy) {
        const table = this._prepareTableDataAuditQs(rowStart, columnStart, maxColumns, auditQA, selectedPolicy);
        
        var count =0;
        var maxRowCount = 4

        if(reportContext.reportType instanceof TwentyOneDayChallengeType){
            maxRowCount = 7;
        }
        let data : any[][] = [];
        table.forEach(element=>{

            data.push(element);
            if(count > maxRowCount){
                const slide = this.formatTableSlidePolicy(pptx.addNewSlide('CONTENT_SLIDE'), reportContext, selectedPolicy.name);

        if (groupText !== '') {
            this.fillSlide(slide, new SlideText(groupText, {
                x: 0.25,
                y: 0.55,
                fontSize: 10,
                fontFace: CONSTANTS.FONTS.BOLD,
                color: '696C76',
                align: 'left', // This should be right aligned and placed in the upper right, but right align seems broken
            }));
        }
        
        this.fillSlide(slide, new SlideTable(data, {x: 0.325, y: 1.6, w: 9.275, fontFace: CONSTANTS.FONTS.NORMAL, autoPage: false}));
        count = 0;
        data = [];     
        maxRowCount = 5;
    }else{

            count++;
    }
        });
        
        if(count == 0)
        return;
        
        const slide = this.formatTableSlidePolicy(pptx.addNewSlide('CONTENT_SLIDE'), reportContext, selectedPolicy.name);

        if (groupText !== '') {
            this.fillSlide(slide, new SlideText(groupText, {
                x: 0.25,
                y: 0.55,
                fontSize: 10,
                fontFace: CONSTANTS.FONTS.BOLD,
                color: '696C76',
                align: 'left', // This should be right aligned and placed in the upper right, but right align seems broken
            }));
        }
        
        this.fillSlide(slide, new SlideTable(data, {x: 0.325, y: 1.6, w: 9.275, fontFace: CONSTANTS.FONTS.NORMAL, autoPage: false}));
    }

    private formatTableSlide(slide: Slide, context: ReportContext, comment?:boolean) {
        slide = this.formatSlideLayout(slide);
        if(comment !== null && comment == true){
        this.fillSlide(slide, new SlideText(context.reportType.title+" Comments", {
            x: 0.25,
            y: 0.3,
            fontSize: 18,
            fontFace: CONSTANTS.FONTS.BOLD
        }));}else{

            this.fillSlide(slide, new SlideText(context.reportType.title, {
                x: 0.25,
                y: 0.3,
                fontSize: 18,
                fontFace: CONSTANTS.FONTS.BOLD
            })); 
        }
        if(this.healthSystem == undefined){
            this.fillSlide(slide, new SlideText('Facility:', {x: 0.25, y: 0.75, fontSize: 10, fontFace: CONSTANTS.FONTS.BOLD}));
            this.fillSlide(slide, new SlideText(context.facility.name, {
                x: 1.50,
                y: 0.75,
                fontSize: 10,
                fontFace: CONSTANTS.FONTS.NORMAL
            }));
        }else{
            this.fillSlide(slide, new SlideText('Health System:', {x: 0.25, y: 0.75, fontSize: 10, fontFace: CONSTANTS.FONTS.BOLD}));
            this.fillSlide(slide, new SlideText(this.healthSystem, {
                x: 1.50,
                y: 0.75,
                fontSize: 10,
                fontFace: CONSTANTS.FONTS.NORMAL
            }));

        }
        this.fillSlide(slide, new SlideText('Date:', {x: 0.25, y: 0.95, fontSize: 10, fontFace: CONSTANTS.FONTS.BOLD}));
        this.fillSlide(slide, new SlideText(`From ${PptService.dateObjectToString(context.fromDate)} To ${PptService.dateObjectToString(context.toDate)}`, {
            x: 1.50,
            y: 0.95,
            fontSize: 10,
            fontFace: CONSTANTS.FONTS.NORMAL
        }));
        this.fillSlide(slide, new SlideText('Shift:', {x: 0.25, y: 1.15, fontSize: 10, fontFace: CONSTANTS.FONTS.BOLD}));
        this.fillSlide(slide, new SlideText(GetDisplayStringForTimeShift(TimeShift[context.timeShift]), {
            x: 1.50,
            y: 1.15,
            fontSize: 10,
            fontFace: CONSTANTS.FONTS.NORMAL
        }));
        if (context.reportView || context.reportMetric) {
            let reportText = '';
            if (context.reportView != null) {
                reportText += context.reportView.title;

                if (context.reportView.getSelectedTab() != null) {
                    reportText += ` - ${context.reportView.getSelectedTab().title}`;
                }
            }
            if (context.reportMetric != null) {
                reportText += context.reportMetric.name;
            }
            this.fillSlide(slide, new SlideText('Report: ', {x: 0.25, y: 1.35, fontSize: 10, fontFace: CONSTANTS.FONTS.BOLD}));
            this.fillSlide(slide, new SlideText(reportText, {x: 1.50, y: 1.35, fontSize: 10, fontFace: CONSTANTS.FONTS.NORMAL}));
        }

        return slide;
    }

    private formatTableSlidePolicy(slide: Slide, context: ReportContext, policy?:string) {
        slide = this.formatSlideLayout(slide);
        if(this.healthSystem == undefined){

            this.fillSlide(slide, new SlideText('Facility:', {x: 0.25, y: 0.75, fontSize: 10, fontFace: CONSTANTS.FONTS.BOLD}));
            this.fillSlide(slide, new SlideText(context.facility.name, {
                x: 1.50,
                y: 0.75,
                fontSize: 10,
                fontFace: CONSTANTS.FONTS.NORMAL
            }));    


        }else{
            this.fillSlide(slide, new SlideText('Health System:', {x: 0.25, y: 0.75, fontSize: 10, fontFace: CONSTANTS.FONTS.BOLD}));
            this.fillSlide(slide, new SlideText(this.healthSystem, {
                x: 1.50,
                y: 0.75,
                fontSize: 10,
                fontFace: CONSTANTS.FONTS.NORMAL
            }));  
        }
            
        this.fillSlide(slide, new SlideText('Policy:', {x: 0.25, y:1, fontSize: 10, fontFace: CONSTANTS.FONTS.BOLD}));
        this.fillSlide(slide, new SlideText(policy, {
            x: 1.50,
            y: 1,
            fontSize: 10,
            fontFace: CONSTANTS.FONTS.NORMAL
        }));

        return slide;
    }

    private _headCell(title: string): SlideTableCell {
        const headCellOptions = {
            fill: 'd9dbe3',
            fontSize: 10,
            fontFace: CONSTANTS.FONTS.BOLD,
            color: '424654',
            rowH: 1.0,
            valign: 'm',
            align: 'c',
            border: 'none',
        };
        return {text: title, options: headCellOptions};
    }

    private _prepareTableData(context: ReportContext, rowStart: number, columnStart: number, maxColumns: number): any[][] {
        const _cellColor = (val: string, isOddRow: boolean) => {
            if (val === '') {
                return isOddRow ? 'FAFAFA' : 'F7F7F8';
            }
            //INC0421825
            if (val === 'N/A') {
                return isOddRow ? 'd3d3d3' : 'd3d3d3';
            }
            const num = parseFloat(val.replace('%', ''));
            if (num >= context.policy.settings.minGreenRate) {
                return isOddRow ? '90ee90' : '90ee91'; //green
            } else if (num >= context.policy.settings.minYellowRate) {
                return isOddRow ? 'ffff33' : 'ffff32'; //yellow
            } else {
                return isOddRow ? 'f95454' : 'f95454'; //red
            }
        };
        const _untintedCell = (value: string, isOddRow: boolean) => {
            return {text: value, options:  {
                fill: isOddRow ? 'FAFAFA' : 'F7F7F8',
                fontSize: 10,
                color: '000000',
                rowH: 1.0,
                valign: 'm',
                align: 'c',
                border: 'none'}
            };
        };
        const _valueCell = (val: string, isOddRow: boolean) => {
            return {
                text: val,
                options:  {
                    fill: _cellColor(val, isOddRow),
                    fontSize: 10,
                    color: '000000',
                    rowH: 1.0,
                    valign: 'm',
                    align: 'c',
                    border: 'none'
                },
            };
        };

        const rows: SlideTableCell[][] = [];
        // Add in the header row
        rows.push([
            this._headCell(context.data.header[0].value), // We always want the first column (unit name)
            ...context.data.header.map(h => this._headCell(h.value)).slice(columnStart, columnStart + maxColumns),
        ]);
        
        // Add in up to CONSTANTS.MAX_ROWS_PER_PAGE rows of data
        context.data.rows.slice(rowStart, rowStart + CONSTANTS.MAX_ROWS_PER_PAGE).forEach((row, rowIndex) => {
            const isOddRow = rowIndex % 2 === 1;
            rows.push([
                _untintedCell(row.cells[0].value, isOddRow), // We always want the first column (unit name)
                ...row.cells.slice(columnStart, columnStart + maxColumns).map(
                  //  (column, index) => column.header ? _untintedCell(column.value, isOddRow) : _valueCell(column.value+"\n"+"n="+column.total, isOddRow))
                  (column, index) => column.header ? _untintedCell(column.value, isOddRow) : _valueCell((column.total!=undefined?column.value+"\n"+"n="+column.total :column.value), isOddRow))
            ]);
        });    

        return rows;
    }

    
    private _prepareTableDataComments(rowStart: number, columnStart: number, maxColumns: number, repType: number, reportContext: UnitReport[]): any[][] {
        const _cellColor = (val: string, isOddRow: boolean) => {
                return isOddRow ? 'FAFAFA' : 'F7F7F8';           
        };
        const _untintedCell = (value: string, isOddRow: boolean) => {
            return {text: value, options:  {
                fill: isOddRow ? 'FAFAFA' : 'F7F7F8',
                fontSize: 10,
                color: '000000',
                rowH: 1.0,
                valign: 'm',
                align: 'c',
                border: 'none'}
            };
        };
        const _valueCell = (val: string, isOddRow: boolean) => {
            return {
                text: val,
                options:  {
                    fill: _cellColor(val, isOddRow),
                    fontSize: 10,
                    color: '000000',
                    rowH: 1.0,
                    valign: 'm',
                    align: 'c',
                    border: 'none'
                },
            };
        };

        const rows: SlideTableCell[][] = [];
        // Add in the header row
        // "Reference Info / Disinfecting Port Protectors / Tubing / Custom Metrics / Catheter / IV Dressings/ Catheter / IV Dressings/ Catheter / IV Dressings"
        if(repType== 0){
        rows.push([ 
            this._headCell("Unit"), this._headCell("Disinfecting Port Protector Comments"), this._headCell("Tubing Comments"), this._headCell("Custom Metrics Comments")]);
            
            reportContext.forEach(element => {
                element.comments.forEach(arrayComment=>{
                    if(arrayComment != undefined){

                    var comments = arrayComment.split("||");

                    if((comments[1] == undefined || comments[1] == "") && (comments[2] == undefined || comments[2] == "") && (comments[3] == undefined || comments[3] == ""))
                    {
                        
                    }else{
                                            
                        rows.push([
                            _valueCell(element.unit.name, false), _valueCell(comments[1] == undefined ? "": comments[1] , false),
                            _valueCell(comments[2] == undefined ? "": comments[2], false),_valueCell(comments[3] == undefined ? "": comments[3], false)]);                    
                    }
                }
                });                
            });

            if(rows.filter(x => x[3].text == "").length == rows.length - 1){
                rows[0][3].text = "";
                rows.forEach(element => {
                    element.splice(3,1);
                });
            }

            if(rows.filter(x => x[2].text == "").length == rows.length - 1){
                rows[0][2].text = "";
                rows.forEach(element => {
                    element.splice(2,1);
                });
            }
            
            if(rows.filter(x => x[1].text == "").length == rows.length - 1){
                rows[0][1].text = "";
                rows.forEach(element => {
                    element.splice(1,1);
                });
            }
        }
        else if(repType== -1){
            rows.push([
                this._headCell("Unit"), this._headCell("21 Day Challenge Comment"), this._headCell("Date")]);
                
                reportContext.forEach(element => {
                    element.comments.forEach(arrayComment=>{
                        if(arrayComment != undefined){
                        var comments = arrayComment.split("||");
                        
                        if((comments[1] == undefined || comments[1] == ""))
                        {
                            
                        }else{
                            rows.push([
                                _valueCell(element.unit.name, false),
                                 _valueCell(comments[1] == undefined ? "": comments[1] , false),
                                 _valueCell(comments[0], false)]);                    
                        }
                    }
                    });                
                });
            }
        else if(repType == 1){
            rows.push([ this._headCell("Unit"), this._headCell("Catheter/Device Comments"), this._headCell("IV Dressing Comments")]);

            reportContext.forEach(element => {
                element.comments.forEach(arrayComment=>{
                    if(arrayComment != undefined){

                    var comments = arrayComment.split("||");
                    comments.splice(0, 4);
                    for(var i = 0; i < comments.length/2; i++){
                        if((comments[i*2] == undefined || comments[i*2] == "") && (comments[i*2 +1] == undefined || comments[i*2 +1] == ""))
                            continue;

                        rows.push([_valueCell(element.unit.name, false), _valueCell(comments[i*2] == undefined ? "": comments[i*2], false),
                            _valueCell(comments[i*2 +1] == undefined ? "": comments[i*2+1], false)]);                    
                        }
                    }
                });                
            });

            if(rows.filter(x => x[2].text == "").length == rows.length - 1){
                rows[0][2].text = "";
                rows.forEach(element => {
                    element.splice(2,1);
                });
            }
            
            if(rows.filter(x => x[1].text == "").length == rows.length - 1){
                rows[0][1].text = "";
                rows.forEach(element => {
                    element.splice(1,1);
                });
            }
        }else{
            rows.push([  this._headCell("Unit"), this._headCell("Catheter/Device Comments"), this._headCell("IV Dressing Comments")]);

            reportContext.forEach(element => {
                element.comments.forEach(arrayComment=>{
                    if(arrayComment != undefined){

                    var comments = arrayComment.split("||");
                    comments.splice(0, 4);
                    for(var i = 0; i < comments.length/2; i++){
                        if((comments[i*2] == undefined || comments[i*2] == "") && (comments[i*2 +1] == undefined || comments[i*2 +1] == ""))
                            continue;

                        rows.push([
                            _valueCell(element.unit.name, false), _valueCell(comments[i*2] == undefined ? "": comments[i*2], false),
                            _valueCell(comments[i*2 +1] == undefined ? "": comments[i*2+1], false)]);                         
                        }
                    }
                });                
            });

            if(rows.filter(x => x[2].text == "").length == rows.length - 1){
                rows[0][2].text = "";
                rows.forEach(element => {
                    element.splice(2,1);
                });
            }
            
            if(rows.filter(x => x[1].text == "").length == rows.length - 1){
                rows[0][1].text = "";
                rows.forEach(element => {
                    element.splice(1,1);
                });
            }
        }
        
        // Add in up to CONSTANTS.MAX_ROWS_PER_PAGE rows of data
        // context.data.rows.slice(rowStart, rowStart + CONSTANTS.MAX_ROWS_PER_PAGE).forEach((row, rowIndex) => {
        //     const isOddRow = rowIndex % 2 === 1;
        //     rows.push([
        //         _untintedCell(row.cells[0].value, isOddRow), // We always want the first column (unit name)
        //         ...row.cells.slice(columnStart, columnStart + maxColumns).map(
        //           //  (column, index) => column.header ? _untintedCell(column.value, isOddRow) : _valueCell(column.value+"\n"+"n="+column.total, isOddRow))
        //           (column, index) => column.header ? _untintedCell(column.value, isOddRow) : _valueCell((column.total!=undefined?column.value+"\n"+"n="+column.total :column.value), isOddRow))
        //     ]);
        // });    
        return rows;
    }

    private _prepareTableDataAuditQs(rowStart: number, columnStart: number, maxColumns: number, auditQuesAns: Array<PolicyResponse>, selectedPolicy: Policy): any[][] {
        const _cellColor = (val: string, isOddRow: boolean) => {
                return isOddRow ? 'FAFAFA' : 'F7F7F8';           
        };
        const _untintedCell = (value: string, isOddRow: boolean) => {
            return {text: value, options:  {
                fill: isOddRow ? 'FAFAFA' : 'F7F7F8',
                fontSize: 10,
                color: '000000',
                rowH: 1.0,
                valign: 'm',
                align: 'c',
                border: 'none'}
            };
        };
        const _valueCell = (val: string, isOddRow: boolean) => {
            return {
                text: val,
                options:  {
                    fill: _cellColor(val, isOddRow),
                    fontSize: 10,
                    bold: isOddRow ? false: true,
                    color: '000000',
                    rowH: 1.0,
                    valign: 'm',
                    align: 'l',
                    border: 'none'
                },
            };
        };

        const rows: SlideTableCell[][] = [];
        // Add in the header row

            console.log(auditQuesAns);
        auditQuesAns.forEach(element => {
            rows.push([_valueCell(element.question, false)]);
            for(var i = 0; i < element.answer.length; i++){
                const ansQ = element.answer[i];
                console.log(ansQ);
                rows.push([_valueCell("• "+ansQ, true)]);

            }
        });

        return rows;
    }


    private formatSlideLayout(slide: Slide) {
        const currMoment = moment(new Date());
        const currentMonth = currMoment.format('MM');
        const currentYear = currMoment.format('YYYY');

        this.fillSlide(slide, new SlideImage(IMAGES.SLIDE_HEADER_IMAGE, {w: 10, h: 0.15}));
        this.fillSlide(slide, new SlideImage(IMAGES.FOOTER_LOGO, {x: '87%', y: '90%', w: 1.0, h: 0.28712}));
        //this.fillSlide(slide, new SlideText('© 3M ' + currentMonth + '/' + currentYear + ' All Rights Reserved', {
        this.fillSlide(slide, new SlideText('© 3M 2020. All Rights Reserved', {
            x: 0.25,
            y: '90%',
            fontSize: 7,
            fontFace: CONSTANTS.FONTS.NORMAL
        }));

        return slide;
    }

    private formatTitleSlide(slide: Slide, reportContext: ReportContext, subheadingText: string, date: string) {
        this.fillSlide(slide, new SlideImage(IMAGES.BRANDING_SLIDE_IMAGE2, {w: 10.0, h: 5.67}));
        this.fillSlide(slide, new SlideText(reportContext.reportType.title, {
            x: 0.2,
            y: 1.5,
            w: 3.6,
            fontSize: 22,
            color: '000000',
            fontFace: '3MCircularTT-Bold'
        }));

        const dateText: string = `From ${PptService.dateObjectToString(reportContext.fromDate)} To ${PptService.dateObjectToString(reportContext.toDate)}`;
        this.fillSlide(slide, new SlideText(dateText, {
            x: 0.2,
            y: 2.0,
            fontSize: 16,
            color: '000000',
            fontFace: '3MCircularTT-Book'
        }));    

        if (subheadingText != '') {
            this.fillSlide(slide, new SlideText(subheadingText, {
                x: 0.2,
                y: 2.5,
                fontSize: 16,
                color: '000000',
                fontFace: '3MCircularTT-Book'
            }));    
        }
        console.log(this.healthSystem);
        if(this.healthSystem == undefined){
            this.fillSlide(slide, new SlideText(reportContext.facility.name, {
                x: 0.2,
                y: 3,
                fontSize: 16,
                color: '000000',
                fontFace: '3MCircularTT-Book'
            }));      

        }else{
            this.fillSlide(slide, new SlideText(this.healthSystem, {
                x: 0.2,
                y: 3,
                fontSize: 16,
                color: '000000',
                fontFace: '3MCircularTT-Book'
            }));
        }
        this.fillSlide(slide, new SlideText(`Generated ${date}`, {
            x: 0.2,
            y: '80%',
            fontSize: 12,
            color: '000000',
            fontFace: '3MCircularTT-Book'
        }));

        return slide;
    }

    private fillSlide(slide: Slide, element: SlideElement): void {
        element.apply(slide);
    }
}
